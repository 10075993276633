import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';

import { UtilitiesService } from './utilities.service';
import { UserSessionService } from './user-session.service';
import { Anexo, Email, EmailSent } from './business-model-interfaces/email';
import { Minuta, NewMinuta } from './business-model-interfaces/minuta';
import { AdministradorSave, CondominioContacts, getCondominioEntidadesRegAtividadeAPI, getCondominioEntities, getCondominioPaymentInfo, GetCondominioTitularBancarioFlatAPI, GetEntitiesContactDetails, getFraccoesLightApi, TipoComunicacao, TitularBancarioSave } from './business-model-interfaces/condominios';
import { custosComunicacoes, custosComunicacoesListCorrespondencia, getEntitiesToNoticeAPI, getTextoFilledToCommunicateApi, getTextosApi, RegistoComunicacaoCorrespondenciaApi, RegistoComunicacaoSave, RegistoComunicacaoSendAndSave, RegistoCTTSaveDetailed, saveEntityWithoutDebt, TipoEntidade } from './business-model-interfaces/comunicacoes';
import { editAgendDate } from './edit-pay-agendamento/edit-pay-agendamento.component';
import * as interfaces from './api-requests';
import { Orcamento } from './business-model-interfaces/orcamentos';
import { PermissionAction, PermissionKey } from './business-model-interfaces/permissions';
import { getFraccoesAPI, getRegistoProprietariosListAPI, saveProprietario, saveProprietarioAPI } from './business-model-interfaces/fraccoes';
import { getEstadosContenciososAPI } from './business-model-interfaces/estados-contenciosos';
import { EntityType, getCondominoDetailsAPI, getCondominoDetailsSimpleAPI, getCondominoNoticeInfoAPI, getContenciosoAcordoPDFInfo, getContenciososApi, getEntityAPI, getEstadoContenciosoAPI, getEstadosContenciososByFraccao, saveEstadoContencioso, saveEstadoContenciosoAPI } from './condominos';
import { getAvisosCobrancaHypothesesAPI, getAvisosCobrancaHypothesisDetailsAPI, savehypothesisBodyAPI } from './configurations';
import { ContasCorrentesAPI } from './business-model-interfaces/contas-correntes';
import { RegistoAtividadeSave } from './business-model-interfaces/registo-atividade';
import { AssembleiasPresencas, EntitiesCommunicationAlreadySentAPI, GetAssembleiasConfigsAPI, GetAssembleiasDetailsAPI, GetAssembleiasParamsFullInfoAPI, OrdemTrabalhoParametros, SaveAssembleiaGeralAPI, SaveAssembleiaOrdemTrabalho } from './business-model-interfaces/assembleias';
import { bit } from './business-model-interfaces/application';
import { DelType, GetProcessamentoListQuery, SaveProcessamento } from './business-model-interfaces/processamentos';
import { GetAgendamentoAssembleiaAPI, SaveAgendamentoAssembleia } from './business-model-interfaces/agendamento-assembleias';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // START ======================== ENVIRONMENT SETTINGS (PRODUCTION / TESTING / DEVELOPMENT) =========================== //
  //                                                                                                                      //
  //                                                   *** IMPORTANT ***                                                  //
  //                                                                                                                      //
  // ==================================================================================================================== //
  // envMode = 'PRODUCTION';
  // envMode = 'TESTING';
  envMode = 'DEVELOPMENT';
  // END ========================== ENVIRONMENT SETTINGS (PRODUCTION / TESTING / DEVELOPMENT) =========================== //


  devEmailAddr = 'fredericoperdigao.dev@hotmail.com';
  devMode = false;

  baseUrl = null;
  emailBaseUrl = null;
  mergePdfBaseUrl = null;
  // emailBaseUrlDev = null;

  headers: any = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  });

  authHeaders: any = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'sessionId': '-1'
  });

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  constructor(public http: HttpClient,
    public userSession: UserSessionService,
    public utils: UtilitiesService) {
    switch (this.envMode) {
      case 'PRODUCTION':
        this.baseUrl = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/index.php';
        this.emailBaseUrl = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/sendEmail.php';
        this.mergePdfBaseUrl = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/mergePdf.php';
        // this.emailBaseUrlDev = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/sendEmail.php';
        break;
      case 'TESTING':
        this.baseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/test/index.php';
        this.emailBaseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/test/sendEmail.php';
        this.mergePdfBaseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/test/mergePdf.php';
        // this.emailBaseUrlDev = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/sendEmailDev.php';
        this.devMode = true;
        break;
      case 'DEVELOPMENT':
        this.baseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/index.php';
        this.emailBaseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/sendEmail.php';
        // this.baseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/index.php';
        // this.emailBaseUrl = 'https://63-250-57-22.cloud-xip.com/primo-api/public/sendEmail.php';
        this.mergePdfBaseUrl = 'https://condominios.vertisprime.pt/api.vertisprime.pt/public/mergePdf.php';
        this.devMode = true;
        break;
    }
  }

  setAuthHeader(userId) {
    this.authHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'sessionId': userId
    });
  }

  unsetAuthHeader() {
    this.authHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'sessionId': '-1'
    });
  }

  login(username: string, password: string): Observable<any> {
    let body = {
      username: username,
      password: password,
    }
    return this.http.post(this.baseUrl + '/auth/login', body, { headers: this.headers });
  }

  logout(): Observable<any> {
    let body = {
      id: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/auth/logout', body, { headers: this.authHeaders });
  }

  getCompanyInfo(): Observable<any> {
    return this.http.get(this.baseUrl + '/appconfig/companyinfo', { headers: this.authHeaders });
  }

  // VERTIS CAIXA
  loginCaixa(username: string, password: string, force = '0'): Observable<any> {
    let body = {
      username: username,
      password: password,
      force: force,
    }
    return this.http.post(this.baseUrl + '/auth/logincaixavertis', body, { headers: this.authHeaders });
  }
  logoutCaixa(nextUserId = null): Observable<any> {
    let body = {
      id_user: this.userSession.getUserId(),
      next_id_user: nextUserId,
    }
    return this.http.post(this.baseUrl + '/auth/logoutcaixavertisREVIEW', body, { headers: this.authHeaders });
  }
  checkUserAccess(module: PermissionKey, action: PermissionAction): Observable<interfaces.checkUserAccessApi> {
    return this.http.get<interfaces.checkUserAccessApi>(this.baseUrl + '/permissions/checkuseraccess/' + module + '/' + action, { headers: this.authHeaders });
  }

  checkIfHasAccessToMenuEntry(): Observable<{ success: boolean, data: Array<{ module: string, allowed: boolean }> }> {
    return this.http.get<{ success: boolean, data: Array<{ module: string, allowed: boolean }> }>(this.baseUrl + '/permissions/checkmenuentryaccess', { headers: this.authHeaders });
  }



  // FUNCIONARIOS ENTITY
  getFuncionarios(page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/funcionarios/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getAllFuncionarios(): Observable<any> {
    return this.http.get(this.baseUrl + '/funcionarios/getall', { headers: this.authHeaders });
  }
  delFuncionarios(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/funcionarios/delete', body, { headers: this.authHeaders });
  }
  addFuncionario(first_name, last_name, title, phone, email): Observable<any> {
    let body = {
      first_name: first_name,
      last_name: last_name,
      title: title,
      phone: phone,
      email: email,
    }
    return this.http.post(this.baseUrl + '/funcionarios/add', body, { headers: this.authHeaders });
  }
  updateFuncionario(id, first_name, last_name, title, phone, email): Observable<any> {
    let body = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      title: title,
      phone: phone,
      email: email,
    }
    return this.http.post(this.baseUrl + '/funcionarios/update', body, { headers: this.authHeaders });
  }



  // UTILIZADORES ENTITY
  getUtilizadores(page: number = 1, limit: number = -1, keyword: string = null, sortParam: string = null, sortDirection: string = null, usersType: boolean = false): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    let blockedUsers = (usersType) ? '1' : '0';
    return this.http.get(this.baseUrl + '/utilizadores/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection + "/" + blockedUsers, { headers: this.authHeaders });
  }
  delUtilizadores(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/utilizadores/delete', body, { headers: this.authHeaders });
  }
  addUtilizadores(username, password, first_name, last_name, title, permissions, hora_inicio, hora_fim, access_days): Observable<any> {
    let body = {
      username: username,
      password: password,
      first_name: first_name,
      last_name: last_name,
      title: title,
      permission_model: permissions.value,
      permissions: JSON.stringify(permissions.model),
      hora_inicio: hora_inicio,
      hora_fim: hora_fim,
      access_days: access_days
    }
    return this.http.post(this.baseUrl + '/utilizadores/add', body, { headers: this.authHeaders });
  }
  changePassword(id, password): Observable<any> {
    let body = {
      id: id,
      password: password,
    }
    return this.http.post(this.baseUrl + '/utilizadores/changepassword', body, { headers: this.authHeaders });
  }
  accessControl(id, is_blocked): Observable<any> {
    let body = {
      id: id,
      is_blocked: (is_blocked) ? 1 : 0,
    }
    return this.http.post(this.baseUrl + '/utilizadores/accesscontrol', body, { headers: this.authHeaders });
  }
  getUtilizadorDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/utilizadores/getdetails/' + id, { headers: this.authHeaders });
  }
  getUtilizadorFrontOffice(): Observable<any> {
    return this.http.get(this.baseUrl + '/utilizadores/howisfrontoffice', { headers: this.authHeaders });
  }
  updateUtilizadoresGeral(id, first_name, username, last_name, title, email, phone, last_login, is_blocked, is_admin, address, profile_img, utilizador_caixa, utilizador_balcao): Observable<any> {
    let body = {
      id: id,
      first_name: first_name,
      username: username,
      last_name: last_name,
      title: title,
      email: email,
      phone: phone,
      last_login: last_login,
      is_blocked: is_blocked,
      is_admin: is_admin,
      address: address,
      profile_img: profile_img,
      utilizador_caixa: (utilizador_caixa) ? 1 : 0,
      utilizador_balcao: (utilizador_balcao) ? 1 : 0,
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/geral', body, { headers: this.authHeaders });
  }
  updateUtilizadoresPermissions(id, permissions, permission_model, super_admin, pagamento_despesas = '0', pagamento_creditos = '0'): Observable<any> {
    let body = {
      id: id,
      permissions: (permissions) ? JSON.stringify(permissions) : null,
      permission_model: permission_model,
      super_admin: super_admin,
      pagamento_despesas: (pagamento_despesas) ? '1' : '0',
      pagamento_creditos: (pagamento_creditos) ? '1' : '0',
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/permissions', body, { headers: this.authHeaders });
  }
  updateUtilizadoresSchedule(id, hora_inicio, hora_fim, access_days, access_days_model): Observable<any> {
    let body = {
      id: id,
      hora_inicio: hora_inicio,
      hora_fim: hora_fim,
      access_days: access_days,
      access_days_model: access_days_model,
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/accessdays', body, { headers: this.authHeaders });
  }




  // CONDOMINIO ENTITY
  getAllActiveCondominios(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getallactive', { headers: this.authHeaders });
  }
  getCondominiums(page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null, activo = 1, construcao = 0, inactivo = 0, angariacao = 0, rescisao = 0, idCondominioSelected = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    idCondominioSelected = (idCondominioSelected) ? idCondominioSelected : 'NULL';

    return this.http.get(this.baseUrl + '/condominios/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection + '/' + activo + '/' + construcao + '/' + inactivo + '/' + angariacao + '/' + rescisao + '/' + idCondominioSelected, { headers: this.authHeaders });
  }
  getContactsByCondominio(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getallcontactlist', { headers: this.authHeaders });
  }
  getCondominioContacts(cod_condominio): Observable<{ success, data: Array<CondominioContacts> }> {
    return this.http.get<{ success, data: Array<CondominioContacts> }>(this.baseUrl + '/condominios/getcontactslist/' + cod_condominio, { headers: this.authHeaders });
  }
  getContactsByEntities(): Observable<any> {
    return this.http.get(this.baseUrl + '/entities/getallcontactlist', { headers: this.authHeaders });
  }
  getCondominiumDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getdetails/' + id, { headers: this.authHeaders });
  }
  getCondominioPaymentInfo(cod): Observable<getCondominioPaymentInfo> {
    return this.http.get<getCondominioPaymentInfo>(this.baseUrl + '/condominios/getpaymentinfo/' + cod, { headers: this.authHeaders });
  }
  getCondominiumDetailsByCod(cod): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getdetailsbycod/' + cod, { headers: this.authHeaders });
  }
  getFraccoesCount(activo = 1, construcao = 0, inactivo = 0, angariacao = 0, rescisao = 0): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoescount/' + activo + '/' + construcao + '/' + inactivo + '/' + angariacao + '/' + rescisao, { headers: this.authHeaders });
  }
  getCondominioMaxCod(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getmaxcod', { headers: this.authHeaders });
  }
  getCondZonasDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getzonasdetails/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesdetails/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesRecebimentos(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesrecebimentos/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesQuota(codCondominio, fraccoes): Observable<any> {
    let body = {
      cod_condominio: codCondominio,
      fraccoes: fraccoes,
    }
    return this.http.post(this.baseUrl + '/condominios/getfraccoesquota', body, { headers: this.authHeaders });
  }
  getCondFraccoes(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoes/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesLight(cod: string): Observable<getFraccoesLightApi> {
    return this.http.get<getFraccoesLightApi>(this.baseUrl + '/condominios/getfraccoeslight/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesDetailsLight(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesdetailslight/' + cod, { headers: this.authHeaders });
  }
  getOrcCondFraccoesDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/orcamentogetfraccoesdetails/' + cod, { headers: this.authHeaders });
  }
  getFraccoesCircular(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoescircular/' + cod_condominio, { headers: this.authHeaders });
  }
  fraccoesEmDivida(cod_condominio, intType = null, start = null, end = null, estadoContencioso = null): Observable<any> {
    // intType = { DATE, VALUE }
    let now = this.utils.getFormatedDate(new Date());

    switch (intType) {
      case 'DATE':
        start = (start) ? this.utils.getFormatedDate(start) : 'NULL';
        end = (end) ? this.utils.getFormatedDate(end) : 'NULL';
        break;
      case 'VALUE':
        start = (start) ? Number(start) : 'NULL';
        end = (end) ? Number(end) : 'NULL';
        break;
    }

    return this.http.get(this.baseUrl + '/condominios/getfraccoesdividaV2/' + cod_condominio + '/' + intType + '/' + start + '/' + end + '/' + now + '/' + estadoContencioso, { headers: this.authHeaders });
  }

  // getCondAssembleiasDetails(cod: string): Observable<any> {
  //   return this.http.get(this.baseUrl + '/condominios/getassembleiasdetails/' + cod, { headers: this.authHeaders });
  // }
  getCondominioTopBarInfo(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/gettopbarinfo/' + cod, { headers: this.authHeaders });
  }
  getCondContasDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontasdetails/' + cod, { headers: this.authHeaders });
  }
  getContasByCondominio(cod, exercicio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontaslist/' + cod + '/' + exercicio, { headers: this.authHeaders });
  }
  getContaPrincipal(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontaprincipal/' + cod_condominio, { headers: this.authHeaders });
  }
  getCondSaldosIniciaisDetails(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/getsaldosiniciaisdetails', body, { headers: this.authHeaders });
  }
  updateSaldosIniciaisZona(codCondominio, zonaList, saldosIniciaisConfig): Observable<any> {
    let body = {
      cod_condominio: codCondominio,
      list: zonaList,
      saldo_inicial_config: JSON.stringify(saldosIniciaisConfig),
    }
    return this.http.post(this.baseUrl + '/zonas/updatesaldosiniciais', body, { headers: this.authHeaders });
  }
  savePagAnterior(id, cod_condominio, cod_fraccao, cod_pagador, dt_valor, data, descricao, credito, valor): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      cod_fraccao: cod_fraccao,
      cod_pagador: cod_pagador,
      dt_valor: dt_valor,
      data: data,
      credito: credito,
      valor: valor,
      descricao: descricao,
    }
    return this.http.post(this.baseUrl + '/condominios/savepaganterior', body, { headers: this.authHeaders });
  }
  savePagAnteriorList(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/savepaganteriorlist', body, { headers: this.authHeaders });
  }
  getCondContactosDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontactosdetails/' + cod, { headers: this.authHeaders });
  }
  addCondominium(code, exerc, name, address, classificacao = null, onedrive_link = null, bitrix_link = null): Observable<any> {
    let body = {
      code: code,
      exerc: exerc,
      name: name,
      address: address,
      classificacao: (classificacao) ? classificacao.toUpperCase() : null,
      onedrive_link: onedrive_link,
      bitrix_link: bitrix_link,
    }
    return this.http.post(this.baseUrl + '/condominios/add', body, { headers: this.authHeaders });
  }
  createCaixa(cod_condominio): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
    }
    return this.http.post(this.baseUrl + '/condominios/createcaixa', body, { headers: this.authHeaders });
  }
  delCondominiums(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/delete', body, { headers: this.authHeaders });
  }
  updateGeralCondominio(id, estado, cod, exercicio, nome, morada, pag_orcamento, pag_fr, fr, fr_perc, latitude, longitude, classificacao = null, onedrive_link = null, bitrix_link = null): Observable<any> {
    let body = {
      id: id,
      estado: estado,
      cod: cod,
      exercicio: exercicio,
      nome: nome,
      morada: morada,
      pag_orcamento: pag_orcamento,
      pag_fr: pag_fr,
      fr: fr,
      fr_perc: fr_perc,
      latitude: latitude,
      longitude: longitude,
      classificacao: (classificacao) ? classificacao.toUpperCase() : null,
      onedrive_link: onedrive_link,
      bitrix_link: bitrix_link,
    }
    return this.http.post(this.baseUrl + '/condominios/update/geral', body, { headers: this.authHeaders });
  }
  updateSeguroCondominio(id, cod_companhia, n_apolice, dt_inicio, dt_fim, pagamento, janeiro, fevereiro, marco, abril, maio, junho, julho, agosto, setembro, outubro, novembro, dezembro, obs): Observable<any> {
    let body = {
      id: id,
      cod_companhia: cod_companhia,
      n_apolice: n_apolice,
      dt_inicio: dt_inicio,
      dt_fim: dt_fim,
      pagamento: pagamento,
      janeiro: (janeiro) ? '1' : '0',
      fevereiro: (fevereiro) ? '1' : '0',
      marco: (marco) ? '1' : '0',
      abril: (abril) ? '1' : '0',
      maio: (maio) ? '1' : '0',
      junho: (junho) ? '1' : '0',
      julho: (julho) ? '1' : '0',
      agosto: (agosto) ? '1' : '0',
      setembro: (setembro) ? '1' : '0',
      outubro: (outubro) ? '1' : '0',
      novembro: (novembro) ? '1' : '0',
      dezembro: (dezembro) ? '1' : '0',
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/condominios/update/seguro', body, { headers: this.authHeaders });
  }
  updateDocumentosCondominio(id, dia_emis_aviso, dia_venc, n_contribuinte, identificador_local_EDP, numero_cliente_SMAS, dt_admissao, artigo_matricial, valor_matricial, financas, senha_financas, cartorio_notarial, dt_prop_horiz, livro, folhas, conservatoria_registo_predial, esc_predial, freguesia_registo_predial, dt_licenca_habitab, dt_construcao): Observable<any> {
    let body = {
      id: id,
      dia_emis_aviso: dia_emis_aviso,
      dia_venc: dia_venc,
      n_contribuinte: n_contribuinte,
      identificador_local_EDP: identificador_local_EDP,
      numero_cliente_SMAS: numero_cliente_SMAS,
      dt_admissao: dt_admissao,
      artigo_matricial: artigo_matricial,
      valor_matricial: valor_matricial,
      financas: financas,
      senha_financas: senha_financas,
      cartorio_notarial: cartorio_notarial,
      dt_prop_horiz: dt_prop_horiz,
      livro: livro,
      folhas: folhas,
      conservatoria_registo_predial: conservatoria_registo_predial,
      desc_predial: esc_predial,
      freguesia_registo_predial: freguesia_registo_predial,
      dt_licenca_habitab: dt_licenca_habitab,
      dt_construcao: dt_construcao,
    }
    return this.http.post(this.baseUrl + '/condominios/update/documentos', body, { headers: this.authHeaders });
  }
  updateObsCondominio(id, administador_1, administador_2, administador_3, titular_1, titular_2, titular_3, titular_4, titulares_obs, obs): Observable<any> {
    let body = {
      id: id,
      administador_1: administador_1,
      administador_2: administador_2,
      administador_3: administador_3,
      titular_1: titular_1,
      titular_2: titular_2,
      titular_3: titular_3,
      titular_4: titular_4,
      titulares_obs: titulares_obs,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/condominios/update/observacoes', body, { headers: this.authHeaders });
  }
  updatePenalizacoesCondominio(id, penal_table): Observable<any> {
    let body = {
      id: id,
      penal_table: (penal_table) ? JSON.stringify(penal_table) : JSON.stringify([]),
    }
    return this.http.post(this.baseUrl + '/condominios/update/penalizacoes', body, { headers: this.authHeaders });
  }
  getAllCondominios(keyword: string = 'NULL', all = false): Observable<any> {
    if (keyword.trim() === '') keyword = 'NULL';

    if (all) {
      return this.http.get(this.baseUrl + '/condominios/getall/ALL/' + keyword, { headers: this.authHeaders });
    } else {
      return this.http.get(this.baseUrl + '/condominios/getall/20/' + keyword, { headers: this.authHeaders });
    }
  }
  searchCondAndFraccao(keyword, cod_condomino = null): Observable<any> {
    cod_condomino = (cod_condomino) ? cod_condomino : 'NULL';
    return this.http.get(this.baseUrl + '/condominios/searchcondandfraccao/' + cod_condomino + '/' + keyword, { headers: this.authHeaders });
  }
  getCondominioReconciliationDate(cod_condominio: string): Observable<interfaces.defaultStringApiRequest> {
    let url = new URL(this.baseUrl + '/condominios/getreconciliationdate');
    url.searchParams.set('cod_condominio', cod_condominio);
    return this.http.get<interfaces.defaultStringApiRequest>(url.toString(), { headers: this.authHeaders });
  }

  getAllCondominiosBy(type, keyword): Observable<any> {
    keyword = keyword.replace('PT50', '');
    keyword = keyword.replace('pt50', '');
    keyword = keyword.replace('Pt50', '');
    keyword = keyword.replace('pT50', '');

    return this.http.get(this.baseUrl + '/condominios/getallby/' + type + '/' + keyword, { headers: this.authHeaders });
  }
  delPagAnteriores(list): Observable<any> {
    let body = {
      list: list
    }
    return this.http.post(this.baseUrl + '/condominios/paganteriores/delete', body, { headers: this.authHeaders });
  }
  getCondominioProprietarios(codCondominio, onlyAtual: boolean = null): Observable<getRegistoProprietariosListAPI> {
    let url = new URL(this.baseUrl + '/condominios/proprietarios');
    url.searchParams.set('cod_condominio', codCondominio);
    if (onlyAtual !== null) url.searchParams.set('atual', !!onlyAtual ? '1' : '0');
    return this.http.get<getRegistoProprietariosListAPI>(url.toString(), { headers: this.authHeaders });
  }
  getEntidadesAssociadas(codCondominio, type = null): Observable<getCondominioEntities> {
    if (type === null) type = 'NULL';

    let url = new URL(this.baseUrl + '/condominios/getentidades');
    url.searchParams.set('codCondominio', codCondominio);
    url.searchParams.set('type', type);
    return this.http.get<getCondominioEntities>(url.toString(), { headers: this.authHeaders });
  }
  getTitularesBancariosByConta(cod_conta): Observable<GetCondominioTitularBancarioFlatAPI> {
    let url = new URL(this.baseUrl + '/condominios/titularbancario');
    url.searchParams.set('cod_conta', cod_conta);
    return this.http.get<GetCondominioTitularBancarioFlatAPI>(url.toString(), { headers: this.authHeaders });
  }
  saveTitularBancario(body: TitularBancarioSave): Observable<any> {
    return this.http.post(this.baseUrl + '/condominios/titularbancario/save', body, { headers: this.authHeaders });
  }
  saveAdministrador(body: AdministradorSave): Observable<any> {
    return this.http.post(this.baseUrl + '/condominios/administrador/save', body, { headers: this.authHeaders });
  }
  saveCondominioEntidadesComunicacao(id_assembleia, entities: Array<{ cod_conta: number, tipoComunicacao: TipoComunicacao }>): Observable<any> {
    let body = {
      id_assembleia: id_assembleia,
      entities: entities,
    }
    return this.http.post(this.baseUrl + '/condominios/entidades/comunicar', body, { headers: this.authHeaders });
  }
  getCondEntidadesRegAtividade(cod_condominio): Observable<getCondominioEntidadesRegAtividadeAPI> {
    let url = new URL(this.baseUrl + '/condominios/entidades/registoatividade');
    url.searchParams.set('cod_condominio', cod_condominio);
    return this.http.get<getCondominioEntidadesRegAtividadeAPI>(url.toString(), { headers: this.authHeaders });
  }
  condominioHasTitularesPorEfetivar(cod_condominio, cod_conta = null): Observable<interfaces.defaultBitApiRequest> {
    let url = new URL(this.baseUrl + '/condominios/entidades/hastitularesporefetivar');
    url.searchParams.set('cod_condominio', cod_condominio);
    if (cod_conta != null) url.searchParams.set('cod_conta', cod_conta);
    return this.http.get<interfaces.defaultBitApiRequest>(url.toString(), { headers: this.authHeaders });
  }


  // START - Communications ----------------------------------------------------------------------
  getEntitiesNoticeSuggestion(cod_condominio, id_reconcilicao): Observable<getEntitiesToNoticeAPI> {
    let url = new URL(this.baseUrl + '/communications/getentitiesnoticesuggestion');
    url.searchParams.set('cod_condominio', cod_condominio);
    url.searchParams.set('id_reconcilicao', id_reconcilicao);
    return this.http.get<getEntitiesToNoticeAPI>(url.toString(), { headers: this.authHeaders });
  }
  sendPaymentNoticesEmail(emailsToSend: Array<RegistoComunicacaoSendAndSave>, condominosWithoutDebt: Array<saveEntityWithoutDebt>, id_reconciliacao): Observable<any> {
    let url = new URL(this.baseUrl + '/communications/sendpaymentnoticesemail');
    if (this.devMode) {
      let devEmail = {
        name: 'Developer',
        email: this.devEmailAddr,
      }
      emailsToSend.forEach(el => {
        if (el.email.to && el.email.to.length) el.email.to = [devEmail];
        if (el.email.bcc && el.email.bcc.length) el.email.bcc = [devEmail];
        if (el.email.cc && el.email.cc.length) el.email.cc = [devEmail];
      });
    }
    let body = {
      emails: emailsToSend,
      id_reconciliacao: id_reconciliacao,
      condominosWithoutDebt: condominosWithoutDebt,
    };
    return this.http.post(url.toString(), body, { headers: this.authHeaders });
  }
  sendAndSaveEmail(emailsToSend: Array<RegistoComunicacaoSendAndSave>): Observable<any> {
    let url = new URL(this.baseUrl + '/communications/sendemails');
    if (this.devMode) {
      let devEmail = {
        name: 'Developer',
        email: this.devEmailAddr,
      }
      emailsToSend.forEach(el => {
        if (el.email.to && el.email.to.length) el.email.to = [devEmail];
        if (el.email.bcc && el.email.bcc.length) el.email.bcc = [devEmail];
        if (el.email.cc && el.email.cc.length) el.email.cc = [devEmail];
      });
    }
    let body = {
      emails: emailsToSend
    };
    return this.http.post(url.toString(), body, { headers: this.authHeaders });
  }




  // SEND EMAIL APÎ SERVICES --------------------------------------------------
  getFraccoesCondominos(cod_condominio, start_date, end_date): Observable<any> {
    start_date = (start_date) ? start_date : 'NULL';
    end_date = (end_date) ? end_date : 'NULL';

    return this.http.get(this.baseUrl + '/condominios/getallcondominos/' + cod_condominio + '/' + start_date + '/' + end_date, { headers: this.authHeaders });
  }
  // --------------------------------------------------------------------------

  // SEGUROS ENTITY
  getActSeguros(cod_condominio: string): Observable<any> {
    return this.http.get(this.baseUrl + '/actseguros/getall/' + cod_condominio, { headers: this.authHeaders });
  }
  getActSegORC(id: string): Observable<any> {
    return this.http.get(this.baseUrl + '/actseguros/get/' + id, { headers: this.authHeaders });
  }
  getActSegCond(cod_condominio: string): Observable<any> {
    return this.http.get(this.baseUrl + '/actseguros/getactivo/' + cod_condominio, { headers: this.authHeaders });
  }
  saveActSeguros(id, cod_seguro = null, dt_inicio = null, dt_fim = null, dt_vencimento = null, obrigat = null, capital = null, premio = null, activo = null, cod_condominio = null): Observable<any> {
    let body = {
      id: id,
      cod_seguro: cod_seguro,
      dt_inicio: (dt_inicio) ? this.utils.getFormatedDate(dt_inicio) : null,
      dt_fim: (dt_fim) ? this.utils.getFormatedDate(dt_fim) : null,
      dt_vencimento: (dt_vencimento) ? this.utils.getFormatedDate(dt_vencimento) : null,
      obrigat: obrigat,
      capital: capital,
      premio: premio,
      activo: (activo) ? '1' : '0',
      cod_condominio: cod_condominio,
    }
    return this.http.post(this.baseUrl + '/actseguros/save', body, { headers: this.authHeaders });
  }
  delActSeguros(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/actseguros/delete', body, { headers: this.authHeaders });
  }
  getActSegFraccoesDetails(cod_condominio: string): Observable<any> {
    return this.http.get(this.baseUrl + '/actseguros/getdetails/' + cod_condominio, { headers: this.authHeaders });
  }

  // ZONAS ENTITY
  getZonaDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/zonas/getdetails/' + id, { headers: this.authHeaders });
  }
  getAllDistinctZonas(): Observable<any> {
    return this.http.get(this.baseUrl + '/zonas/getalldistinct', { headers: this.authHeaders });
  }
  addZonaCondominio(cod_condominio, nome, morada, saldo_inicial, saldo_inicial_fr): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      nome: nome,
      morada: morada,
      saldo_inicial: saldo_inicial,
      saldo_inicial_fr: saldo_inicial_fr,
    }
    return this.http.post(this.baseUrl + '/zonas/add', body, { headers: this.authHeaders });
  }
  delZonas(list, cod_condominio): Observable<any> {
    let body = {
      list: list,
      cod_condominio: cod_condominio,
    }
    return this.http.post(this.baseUrl + '/zonas/delete', body, { headers: this.authHeaders });
  }
  updateZonaDetails(id, nome, morada): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      morada: morada,
      // saldo_inicial: saldo_inicial,
      // saldo_inicial_fr: saldo_inicial_fr
    }
    return this.http.post(this.baseUrl + '/zonas/update', body, { headers: this.authHeaders });
  }

  // FRACCOES ENTITY
  getFraccaoDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/fraccoes/getdetails/' + id, { headers: this.authHeaders });
  }
  addFraccaoCondominio(cod_condominio, cod, nome, cod_zona, cod_proprietario, cod_inquilino, permilagem, permilagem_aux_1, permilagem_aux_2, area, n_votos, paga_seg_colect, capital_obrigat, capital_pagar, capital_individ, permilagem_zona, actual_orc, quota_orc, actual_fr, quota_fr, actual_seg, quota_seg, obs, obj): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      cod: cod,
      nome: nome,
      cod_zona: cod_zona,
      cod_proprietario: cod_proprietario,
      cod_inquilino: cod_inquilino,
      permilagem: permilagem,
      permilagem_aux_1: permilagem_aux_1,
      permilagem_aux_2: permilagem_aux_2,
      area: area,
      n_votos: n_votos,
      paga_seg_colect: paga_seg_colect,
      capital_obrigat: capital_obrigat,
      capital_pagar: capital_pagar,
      capital_individ: capital_individ,
      permilagem_zona: permilagem_zona,

      actual_orc: actual_orc,
      quota_orc: quota_orc,
      actual_fr: actual_fr,
      quota_fr: quota_fr,
      actual_seg: actual_seg,
      quota_seg: quota_seg,

      obs: obs,
      obj: obj,
    }
    return this.http.post(this.baseUrl + '/fraccoes/add', body, { headers: this.authHeaders });
  }
  updateFraccaoCondominio(id, cod_condominio, cod, nome, cod_zona, cod_proprietario, cod_inquilino, permilagem, permilagem_aux_1, permilagem_aux_2, area, n_votos, paga_seg_colect, capital_obrigat, capital_pagar, capital_individ, permilagem_zona, actual_orc, quota_orc, actual_fr, quota_fr, actual_seg, quota_seg, obs): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      cod: cod,
      nome: nome,
      cod_zona: cod_zona,
      cod_proprietario: cod_proprietario,
      cod_inquilino: cod_inquilino,
      permilagem: permilagem,
      permilagem_aux_1: permilagem_aux_1,
      permilagem_aux_2: permilagem_aux_2,
      area: area,
      n_votos: n_votos,
      paga_seg_colect: paga_seg_colect,
      capital_obrigat: capital_obrigat,
      capital_pagar: capital_pagar,
      capital_individ: capital_individ,
      permilagem_zona: permilagem_zona,

      actual_orc: actual_orc,
      quota_orc: quota_orc,
      actual_fr: actual_fr,
      quota_fr: quota_fr,
      actual_seg: actual_seg,
      quota_seg: quota_seg,

      obs: obs,
    }
    return this.http.post(this.baseUrl + '/fraccoes/update', body, { headers: this.authHeaders });
  }
  delFraccoes(list, cod_condominio): Observable<any> {
    let body = {
      list: list,
      cod_condominio: cod_condominio,
    }
    return this.http.post(this.baseUrl + '/fraccoes/delete', body, { headers: this.authHeaders });
  }
  getFracaoRegistoProprietarios(id_fracao: string | number): Observable<getRegistoProprietariosListAPI> {
    return this.http.get<getRegistoProprietariosListAPI>(this.baseUrl + '/fraccoes/getregistoproprietarios/' + id_fracao, { headers: this.authHeaders });
  }
  getFraccoesByProprietario(cod_proprietario): Observable<getFraccoesAPI> {
    let url = new URL(this.baseUrl + '/fraccoes/getbycondomino');
    url.searchParams.set('cod_proprietario', cod_proprietario);
    return this.http.get<getFraccoesAPI>(url.toString(), { headers: this.authHeaders });
  }
  saveProprietario(data: saveProprietario): Observable<saveProprietarioAPI> {
    let body = {
      ...data,
      data_inicio: data.data_inicio ? this.utils.getFormatedDate(data.data_inicio) : null
    };
    return this.http.post<saveProprietarioAPI>(this.baseUrl + '/fraccoes/saveproprietario', body, { headers: this.authHeaders });
  }
  checkRecibosChanged(data: saveProprietario): Observable<any> {
    let body = {
      ...data,
      data_inicio: data.data_inicio ? this.utils.getFormatedDate(data.data_inicio) : null
    };
    return this.http.post(this.baseUrl + '/fraccoes/changeproprietariocheckrecibos', body, { headers: this.authHeaders });
  }
  getContenciososByFracao(id_fracao): Observable<getEstadosContenciososByFraccao> {
    return this.http.get<getEstadosContenciososByFraccao>(this.baseUrl + '/condominos/getcontenciososbyfracao/' + id_fracao, { headers: this.authHeaders });
  }


  // CONTACTOS ENTITY
  getContactoDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/contactos/getdetails/' + id, { headers: this.authHeaders });
  }
  addContactoCondominio(cod_condominio, nome, tipo_contacto, n_contribuinte, telefone_1, morada, email_1, obs, conta_principal): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      nome: nome,
      tipo_contacto: tipo_contacto,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      morada: morada,
      email_1: email_1,
      obs: obs,
      conta_principal: conta_principal,
    }
    return this.http.post(this.baseUrl + '/contactos/add', body, { headers: this.authHeaders });
  }
  saveContacto(contactoId, contactoCod, condominioCod, nome, email_1, email_2, email_3, forma_tratamento, morada, n_contribuinte, telefone_1, tipo_contacto, obs): Observable<any> {
    let body = {
      id: contactoId,
      cod: contactoCod,
      cod_condominio: condominioCod,
      nome: nome,
      email_1: email_1,
      email_2: email_2,
      email_3: email_3,
      forma_tratamento: forma_tratamento,
      morada: morada,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      tipo_contacto: tipo_contacto,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/contactos/save', body, { headers: this.authHeaders });
  }
  delContactos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/contactos/delete', body, { headers: this.authHeaders });
  }
  updateContactoGeral(id, cod, nome, email_1, email_2, email_3, forma_tratamento, morada, n_contribuinte, telefone_1, tipo_contacto): Observable<any> {
    let body = {
      id: id,
      cod: cod,
      nome: nome,
      email_1: email_1,
      email_2: email_2,
      email_3: email_3,
      forma_tratamento: forma_tratamento,
      morada: morada,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      tipo_contacto: tipo_contacto,
    }
    return this.http.post(this.baseUrl + '/contactos/update/geral', body, { headers: this.authHeaders });
  }
  updateContactoObs(id, obs): Observable<any> {
    let body = {
      id: id,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/contactos/update/observacoes', body, { headers: this.authHeaders });
  }


  // ASSEMBLEIAS ENTITY
  getAssembleiaDetails(id: number): Observable<GetAssembleiasDetailsAPI> {
    return this.http.get<GetAssembleiasDetailsAPI>(this.baseUrl + '/assembleias/getdetails/' + id, { headers: this.authHeaders });
  }
  getAssembleiasConfigs(): Observable<GetAssembleiasConfigsAPI> {
    return this.http.get<GetAssembleiasConfigsAPI>(this.baseUrl + '/assembleias/getconfigs', { headers: this.authHeaders });
  }
  addAssembleiaCondominio(cod_condominio, dt, dt_alter, dt_fim, morada, extraordinaria, assunto): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      dt: dt,
      dt_alter: dt_alter,
      dt_fim: dt_fim,
      morada: morada,
      extraordinaria: extraordinaria,
      assunto: assunto,
    }
    return this.http.post(this.baseUrl + '/assembleias/add', body, { headers: this.authHeaders });
  }

  saveAssembleiaGeral(id, codCondominio, ordensTrabalhos: Array<SaveAssembleiaOrdemTrabalho>, dt: Date, dt_alter: Date, dt_fim: Date, extraordinaria, ficheiro, morada, codPostal, localidade, autorConvocatoria: 1 | 2 | 3, ata = null, utilizador_id = null, data_limite = null, conv_enviada = '0', conv_ordens_trabalho = '[]'): Observable<SaveAssembleiaGeralAPI> {
    let body = {
      id: id,
      cod_condominio: codCondominio,
      dt: (dt) ? this.utils.getFormatedDate(dt) : null,
      dt_alter: (dt_alter) ? this.utils.getFormatedDate(dt_alter) : null,
      dt_fim: (dt_fim) ? this.utils.getFormatedDate(dt_fim) : null,

      hora: (dt) ? formatDate(dt, 'HH:mm', this.locale) : null,
      hora_alter: (dt_alter) ? formatDate(dt_alter, 'HH:mm', this.locale) : null,
      hora_fim: (dt_fim) ? formatDate(dt_fim, 'HH:mm', this.locale) : null,

      extraordinaria: extraordinaria,
      ficheiro: ficheiro,
      morada: morada,
      cod_postal: codPostal,
      localidade: localidade,
      autor_convocatoria: autorConvocatoria,
      ordensTrabalhos: ordensTrabalhos,
      ata: (ata) ? JSON.stringify(ata) : null,

      data_convocatoria: null,
      data_reuniao: dt,
      utilizador_id: utilizador_id,
      data_limite: data_limite,

      conv_enviada: conv_enviada,
      conv_ordens_trabalho: conv_ordens_trabalho
    }
    return this.http.post<SaveAssembleiaGeralAPI>(this.baseUrl + '/assembleias/update/geral', body, { headers: this.authHeaders });
  }
  saveDataConvocatoria(id, data_convocatoria): Observable<any> {
    let body = {
      id: id,
      data_convocatoria: data_convocatoria,
      conv_enviada: (data_convocatoria) ? '1' : '0',
    }
    return this.http.post(this.baseUrl + '/assembleias/update/dataconvocatoria', body, { headers: this.authHeaders });
  }
  updateAssembleiaPresencas(id, assembleiaPresencas: Array<AssembleiasPresencas>, ata_concluida: bit = 0, reuniao_realizada: bit = 0): Observable<any> {
    let body = {
      id: id,
      assembleiaPresencas: assembleiaPresencas,
      ata_concluida: ata_concluida,
      reuniao_realizada: reuniao_realizada,
      data_ata: (assembleiaPresencas.find(el => el.present == 1) || reuniao_realizada) ? new Date() : null,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/presencas', body, { headers: this.authHeaders });
  }
  saveAtaAssembleia(id, ata, ordensTrabalhos: Array<SaveAssembleiaOrdemTrabalho>, presencas_ficheiro, representantes_ficheiro, presencas_representantes_ficheiro, numAta = null, numFolha = null, numPag = null, ata_concluida = false, assembleiaPresencas: Array<AssembleiasPresencas> = []): Observable<any> {
    let body = {
      id: id,
      ata: (ata) ? JSON.stringify(ata) : null,
      ordensTrabalhos: ordensTrabalhos,
      presencas_ficheiro: presencas_ficheiro,
      representantes_ficheiro: representantes_ficheiro,
      presencas_representantes_ficheiro: presencas_representantes_ficheiro,
      numAta: numAta,
      numFolha: numFolha,
      numPag: numPag,
      ata_concluida: (ata_concluida) ? '1' : '0',
      data_ata: new Date(),

      assembleiaPresencas: assembleiaPresencas,
      editingUsername: this.userSession.getUserFullName(),
      editingUserId: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/assembleias/update/ata', body, { headers: this.authHeaders });
  }
  saveAtaConcluida(id, ata_concluida: bit = 0): Observable<any> {
    let body = {
      id: id,
      ata_concluida: ata_concluida,
      data_ata: new Date(),
    }
    return this.http.post(this.baseUrl + '/assembleias/update/ataconcluida', body, { headers: this.authHeaders });
  }
  cancelarAssembleia(id, cancelada): Observable<any> {
    let body = {
      id: id,
      cancelada: (cancelada) ? 1 : 0,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/cancelar', body, { headers: this.authHeaders });
  }
  updateAssembleiaOrdTrab(id, assunto): Observable<any> {
    let body = {
      id: id,
      assunto: assunto,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/ordemtrabalhos', body, { headers: this.authHeaders });
  }
  updateAssembleiaConvo(id, texto_final): Observable<any> {
    let body = {
      id: id,
      texto_final: texto_final,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/convocatoria', body, { headers: this.authHeaders });
  }
  updateAssembleiaDelib(id, deliberacoes): Observable<any> {
    let body = {
      id: id,
      deliberacoes: deliberacoes,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/deliberacoes', body, { headers: this.authHeaders });
  }
  updateAssembleiaObs(id, obs): Observable<any> {
    let body = {
      id: id,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/assembleias/update/observacoes', body, { headers: this.authHeaders });
  }
  uploadAtaPdfFile(id, filename, ata_ficheiro_base64): Observable<any> {
    let body = {
      id: id,
      ata_ficheiro: ata_ficheiro_base64,

      data_arquivo: new Date(),

      filename: filename,
      ext: '.pdf',
      origem: 'ATA_ASSEMBLEIA_ASSINADA',
      userId: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/assembleias/uploadatafile', body, { headers: this.authHeaders });
  }
  uploadRepresentantesPdfFile(id, representantes_ficheiro_base64): Observable<any> {
    let body = {
      id: id,
      representantes_ficheiro: representantes_ficheiro_base64,
    }
    return this.http.post(this.baseUrl + '/assembleias/uploadrepresentantesfile', body, { headers: this.authHeaders });
  }
  uploadRegPresencasAnexoPdfFile(id, anexo, ficheiro_base64): Observable<any> {
    let body = {
      id: id,
      anexo: anexo,
      ficheiro: ficheiro_base64,
    }
    return this.http.post(this.baseUrl + '/assembleias/uploadregpresencasfile', body, { headers: this.authHeaders });
  }
  deleteRepresentantesPdfFile(id): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleias/deleterepresentantesfile/' + id, { headers: this.authHeaders });
  }
  uploadAtaAttachmentFile(assembleia_id, tipos_anexo_id, anexo, nome_ficheiro, tipo_ficheiro = 'pdf'): Observable<any> {
    let body = {
      assembleia_id: assembleia_id,
      tipo: tipo_ficheiro,
      anexo: anexo,
      nome_ficheiro: nome_ficheiro,
      tipos_anexo_id: tipos_anexo_id,
    }
    return this.http.post(this.baseUrl + '/assembleias/uploadattachment', body, { headers: this.authHeaders });
  }
  uploadRelatorioAssembleiaFile(assembleia_id, relatorio_assembleia, filename): Observable<any> {
    let body = {
      id: assembleia_id,
      relatorio_assembleia: relatorio_assembleia,
      filename: filename,
      ext: '.pdf',
      origem: 'RELATORIO_ASSEMBLEIA',
      userId: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/assembleias/uploadrelatorioassembleia', body, { headers: this.authHeaders });
  }
  deleteAtaAttachmentFile(id): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleias/deleteattachment/' + id, { headers: this.authHeaders });
  }
  activeAtaAttachmentFile(id): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleias/activeattachment/' + id, { headers: this.authHeaders });
  }
  saveAssembleiaDespesasCtt(id_assembleia, despesas, registosCtt, origin, pagar = false, permission_field = 'DESPESAS'): Observable<any> {
    let body = {
      despesas: despesas,
      registos_ctt: registosCtt,
      pagar: pagar,
      id_assembleia: id_assembleia,
      origin: origin,
      permission_field: permission_field,
    }
    return this.http.post(this.baseUrl + '/assembleias/savedespesasctt', body, { headers: this.authHeaders });
  }
  getAtaFile(id_assembleia, label, tipos_anexos_id: string = null): Observable<any> {
    tipos_anexos_id = tipos_anexos_id ? tipos_anexos_id : 'NULL'
    return this.http.get(this.baseUrl + '/assembleias/getatafile/' + id_assembleia + '/' + label + '/' + tipos_anexos_id, { headers: this.authHeaders });
  }
  saveAssembleiaComentario(id_assembleia: number, comentario: string): Observable<any> {
    let body = {
      id_assembleia: id_assembleia,
      comentario: comentario,
    }
    return this.http.post(this.baseUrl + '/assembleias/comment', body, { headers: this.authHeaders });
  }


  // CONTAS ENTITY
  getContaDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/contas/getdetails/' + id, { headers: this.authHeaders });
  }
  saveConta(id, cod_conta, n_conta, morada, telefone_1, obs, cod_condominio, cod_tipo_conta, banco, titular, nib, dt_abertura, gestor, agencia, conta_principal): Observable<any> {
    let body = {
      id: id,
      cod_conta: cod_conta,
      n_conta: n_conta,
      morada: morada,
      telefone_1: telefone_1,
      obs: obs,
      cod_condominio: cod_condominio,
      banco: banco,
      titular: titular,
      nib: nib,
      dt_abertura: (dt_abertura) ? this.utils.getFormatedDate(dt_abertura) : null,
      gestor: gestor,
      agencia: agencia,
      cod_tipo_conta: cod_tipo_conta,
      conta_principal: (conta_principal) ? '1' : '0',
    }
    return this.http.post(this.baseUrl + '/contas/save', body, { headers: this.authHeaders });
  }
  addContaCondominio(cod_condominio, banco, nib, cod_tipo_conta, dt_abertura, agencia, gestor, telefone_1, morada, obs): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      banco: banco,
      nib: nib,
      cod_tipo_conta: cod_tipo_conta,
      dt_abertura: dt_abertura,
      agencia: agencia,
      gestor: gestor,
      telefone_1: telefone_1,
      morada: morada,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/contas/add', body, { headers: this.authHeaders });
  }
  delContas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/contas/delete', body, { headers: this.authHeaders });
  }
  updateContaGeral(id, cod_tipo_conta, banco, nib, dt_abertura, titular, n_conta, conta_principal): Observable<any> {
    let body = {
      id: id,
      cod_tipo_conta: cod_tipo_conta,
      banco: banco,
      nib: nib,
      dt_abertura: dt_abertura,
      titular: titular,
      n_conta: n_conta,
      conta_principal: conta_principal
    }
    return this.http.post(this.baseUrl + '/contas/update/geral', body, { headers: this.authHeaders });
  }
  updateContaAgencia(id, agencia, gestor, morada, telefone_1): Observable<any> {
    let body = {
      id: id,
      agencia: agencia,
      gestor: gestor,
      morada: morada,
      telefone_1: telefone_1,
    }
    return this.http.post(this.baseUrl + '/contas/update/agencia', body, { headers: this.authHeaders });
  }
  updateContaObs(id, obs): Observable<any> {
    let body = {
      id: id,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/contas/update/observacoes', body, { headers: this.authHeaders });
  }
  checkIfContaHasMovements(cod_conta): Observable<interfaces.defaultBitApiRequest> {
    return this.http.get<interfaces.defaultBitApiRequest>(this.baseUrl + '/contas/hasmovements/' + cod_conta, { headers: this.authHeaders });
  }



  // FORNECEDORES ENTITY
  getFornecedores(page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/fornecedores/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getFornecedorDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/fornecedores/getdetails/' + id, { headers: this.authHeaders });
  }
  getFornecedorDetailsByCod(cod: number): Observable<any> {
    return this.http.get(this.baseUrl + '/fornecedores/getdetailsbycod/' + cod, { headers: this.authHeaders });
  }
  delFornecedores(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/fornecedores/delete', body, { headers: this.authHeaders });
  }
  saveFornecedor(id, nome, morada, n_contribuinte, telefone_1, telefone_2, comissao, obs, email_1, email_2, forma_tratamento, nib, id_tipo, telefone_1_obs, telefone_2_obs, email_1_obs, email_2_obs): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      morada: morada,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      telefone_2: telefone_2,
      comissao: comissao,
      obs: obs,
      email_1: email_1,
      email_2: email_2,
      forma_tratamento: forma_tratamento,
      nib: nib,
      id_tipo: id_tipo,

      telefone_1_obs: telefone_1_obs,
      telefone_2_obs: telefone_2_obs,
      email_1_obs: email_1_obs,
      email_2_obs: email_2_obs,
    }
    return this.http.post(this.baseUrl + '/fornecedores/save', body, { headers: this.authHeaders });
  }
  getAllFornecedores(keyword: string = 'NULL', all = false): Observable<any> {
    if (all) {
      return this.http.get(this.baseUrl + '/fornecedores/getall/ALL/' + keyword, { headers: this.authHeaders });
    } else {
      return this.http.get(this.baseUrl + '/fornecedores/getall/20/' + keyword, { headers: this.authHeaders });
    }
  }
  getAdvogados(keyword: string = null, all = false): Observable<any> {
    let url = new URL(this.baseUrl + '/fornecedores/getalladvogados');

    if (keyword != null) url.searchParams.set('keyword', keyword);
    if (!all) url.searchParams.set('limit', '20');
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  getAgentesExecucao(keyword: string = null, all = false): Observable<any> {
    let url = new URL(this.baseUrl + '/fornecedores/getallagentesexecucao');
    if (keyword != null) url.searchParams.set('keyword', keyword);
    if (!all) url.searchParams.set('limit', '20');
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }


  // PROCURADORES ENTITY
  getProcuradores(page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/procuradores/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getProcuradorDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/procuradores/getdetails/' + id, { headers: this.authHeaders });
  }
  delProcuradores(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/procuradores/delete', body, { headers: this.authHeaders });
  }
  saveProcurador(id, nome, morada, n_contribuinte, telefone_1, telefone_2, obs, email_1, email_2, forma_tratamento, telefone_1_obs, telefone_2_obs, email_1_obs, email_2_obs): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      morada: morada,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      telefone_2: telefone_2,
      obs: obs,
      email_1: email_1,
      email_2: email_2,
      forma_tratamento: forma_tratamento,

      telefone_1_obs: telefone_1_obs,
      telefone_2_obs: telefone_2_obs,
      email_1_obs: email_1_obs,
      email_2_obs: email_2_obs,
    }
    return this.http.post(this.baseUrl + '/procuradores/save', body, { headers: this.authHeaders });
  }
  getAllProcuradores(keyword: string = 'NULL', all = false): Observable<any> {
    if (all) {
      return this.http.get(this.baseUrl + '/procuradores/getall/ALL/' + keyword, { headers: this.authHeaders });
    } else {
      return this.http.get(this.baseUrl + '/procuradores/getall/20/' + keyword, { headers: this.authHeaders });
    }
  }



  // CONDOMINOS ENTITY
  getCondominos(type: string, page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/condominos/getlist/' + type + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getCondominoDetails(id: number): Observable<getCondominoDetailsAPI> {
    return this.http.get<getCondominoDetailsAPI>(this.baseUrl + '/condominos/getdetails/' + id, { headers: this.authHeaders });
  }
  getCondominoDetailsSimple(id: number): Observable<getCondominoDetailsSimpleAPI> {
    return this.http.get<getCondominoDetailsSimpleAPI>(this.baseUrl + '/condominos/getdetailssimple/' + id, { headers: this.authHeaders });
  }
  getCondominoNoticeInfo(cod_proprietario, id_fracao, data_conteudo_comunicacao: Date = null): Observable<getCondominoNoticeInfoAPI> {
    let url = new URL(this.baseUrl + '/condominos/getnoticeinfo/' + cod_proprietario + '/' + id_fracao);
    if (data_conteudo_comunicacao) url.searchParams.set('data_conteudo_comunicacao', this.utils.getFormatedDate(data_conteudo_comunicacao));
    return this.http.get<getCondominoNoticeInfoAPI>(url.toString(), { headers: this.authHeaders });
  }
  getCondominoContacts(cod): Observable<any> {
    return this.http.get(this.baseUrl + '/condominos/getcontacts/' + cod, { headers: this.authHeaders });
  }
  delCondominos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominos/delete', body, { headers: this.authHeaders });
  }
  saveCondomino(id, cod, nome, morada, morada_fact, n_contribuinte, telefone_1, telefone_2, obs, limite_credito, dt_nascimento, profissao, email_1, email_2, other_emails, banco, n_conta, nib, referencia, bi_condomino, matriculas, conjuge, bi_conjuge, dt_conjuge, n_contribuinte_conjuge, estado_civil, forma_tratamento, tipo_documento, tipo_condomino, id_procurador, id_tipo, obj, recepcaoDocumentos: { geral, avisos, cartas_cobranca }, telefone_1_obs, telefone_2_obs, email_1_obs, email_2_obs, alterado): Observable<any> {
    let body = {
      id: id,
      cod: cod,
      nome: nome,
      morada: morada,
      morada_fact: morada_fact,
      n_contribuinte: n_contribuinte,
      telefone_1: telefone_1,
      telefone_2: telefone_2,
      obs: obs,
      limite_credito: (limite_credito) ? limite_credito : 0,
      dt_nascimento: dt_nascimento,
      profissao: profissao,
      email_1: email_1,
      email_2: email_2,
      other_emails: other_emails,
      banco: banco,
      n_conta: n_conta,
      nib: nib,
      referencia: referencia,
      bi_condomino: bi_condomino,
      matriculas: matriculas,
      conjuge: conjuge,
      bi_conjuge: bi_conjuge,
      dt_conjuge: dt_conjuge,
      n_contribuinte_conjuge: n_contribuinte_conjuge,
      estado_civil: estado_civil,
      forma_tratamento: forma_tratamento,
      tipo_documento: tipo_documento,
      id_procurador: id_procurador,
      id_tipo: id_tipo,
      tipo_condomino: (tipo_condomino === 'proprietarios') ? '1' : '2',
      obj: obj,

      recepcaoDocumentos: recepcaoDocumentos,

      telefone_1_obs: telefone_1_obs,
      telefone_2_obs: telefone_2_obs,
      email_1_obs: email_1_obs,
      email_2_obs: email_2_obs,
      alterado: alterado
    }
    return this.http.post(this.baseUrl + '/condominos/save', body, { headers: this.authHeaders });
  }
  getAllCondominos(keyword: string = 'NULL', type = 'proprietarios'): Observable<any> {
    return this.http.get(this.baseUrl + '/condominos/getall/50/' + type + '/' + keyword, { headers: this.authHeaders });
  }
  getAllEntities(keyword: string = null, limit: number = 50, includeProcuradores: bit = 0, includeFornecedores: bit = 0): Observable<getEntityAPI> {
    let url = new URL(this.baseUrl + '/entity');
    url.searchParams.set('limit', limit.toString());
    url.searchParams.set('procuradores', includeProcuradores.toString());
    url.searchParams.set('fornecedores', includeFornecedores.toString());
    if (keyword) url.searchParams.set('keyword', keyword);

    return this.http.get<getEntityAPI>(url.toString(), { headers: this.authHeaders });
  }

  saveEstadoContencioso(data: saveEstadoContencioso): Observable<saveEstadoContenciosoAPI> {
    let body = {
      ...data,
      dt_inicio: data.dt_inicio ? this.utils.getFormatedDate(data.dt_inicio) : null
    };
    if (data.primeira_prestacao) body.primeira_prestacao = this.utils.getFormatedDate(data.primeira_prestacao);
    if (data.ajuste_prestacao) body.ajuste_prestacao = this.utils.getFormatedDate(data.ajuste_prestacao);
    if (data.prestacoes_adicionais && data.prestacoes_adicionais.length) body.prestacoes_adicionais = data.prestacoes_adicionais.map(el => {
      el.data = this.utils.getFormatedDate(el.data);
      return el;
    });
    return this.http.post<saveEstadoContenciosoAPI>(this.baseUrl + '/condominos/saveestadocontencioso', body, { headers: this.authHeaders });
  }
  deleteEstadoContencioso(id_registo, del_razao): Observable<interfaces.defaultBooleanApiRequest> {
    let body = {
      id_registo: id_registo,
      del_razao: del_razao,
    }
    return this.http.post<interfaces.defaultBooleanApiRequest>(this.baseUrl + '/condominos/deleteestadocontencioso', body, { headers: this.authHeaders });
  }
  changeEstadoContencioso(id_registo, motivo, action: 'ACTIVATE' | 'DISABLE'): Observable<interfaces.defaultBooleanApiRequest> {
    let body = {
      id_registo: id_registo,
      action: action,
      motivo: motivo,
    }
    return this.http.post<interfaces.defaultBooleanApiRequest>(this.baseUrl + '/condominos/changeestadocontencioso', body, { headers: this.authHeaders });
  }
  getContenciosoPDFInfo(id_fracao, cod_entidade): Observable<getContenciosoAcordoPDFInfo> {
    let url = new URL(this.baseUrl + '/condominos/getcontenciosopdfinfo');
    url.searchParams.set('id_fracao', id_fracao);
    url.searchParams.set('cod_entidade', cod_entidade);
    return this.http.get<getContenciosoAcordoPDFInfo>(url.toString(), { headers: this.authHeaders });
  }
  getCondominoFracoesWithoutContencioso(cod_proprietario): Observable<any> {
    return this.http.get(this.baseUrl + '/condominos/getfracoeswithoutcontencioso/' + cod_proprietario, { headers: this.authHeaders });
  }
  getCondominoDivida(cod_proprietario, data: Date, id_fracao): Observable<interfaces.defaultStringApiRequest> {
    let data_conv = this.utils.getFormatedDate(data);
    return this.http.get<interfaces.defaultStringApiRequest>(this.baseUrl + '/condominos/getdivida/' + cod_proprietario + '/' + data_conv + '/' + id_fracao, { headers: this.authHeaders });
  }
  getProprietarioByContencioso(id_contencioso): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/condominos/getproprietariobycontencioso/' + id_contencioso, { headers: this.authHeaders });
  }


  // TIPOS ENTITY
  getAllTipos(): Observable<any> {
    return this.http.get(this.baseUrl + '/tiposfornecedores/getall', { headers: this.authHeaders });
  }
  getAllCompanhias(): Observable<any> {
    return this.http.get(this.baseUrl + '/companhiasseguro/getall', { headers: this.authHeaders });
  }


  // --------------------- ORCAMENTOS ENTITY - START
  // GET
  getOrcamentosByCondominio(cod_condominio, exercicio): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/getbycondominio/' + cod_condominio + '/' + exercicio, { headers: this.authHeaders });
  }
  getOrcamentoDetailsREVIEW(id): Observable<{
    success: boolean,
    data: {
      orcamento: Array<any>,
      zonas: Array<any>,
      fraccoes: Array<any>,
      fraccoes_fr: Array<any>,
      rubricas: Array<any>,
    }
  }> {
    return this.http.get<{
      success: boolean,
      data: {
        orcamento: Array<any>,
        zonas: Array<any>,
        fraccoes: Array<any>,
        fraccoes_fr: Array<any>,
        rubricas: Array<any>,
      }
    }>(this.baseUrl + '/orcamentos/getdetailsREVIEW/' + id, { headers: this.authHeaders });
  }
  getOrcamentoDetailsByExercicio(cod_condominio, exercicio): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/getdetailsbyexercicio/' + cod_condominio + '/' + exercicio, { headers: this.authHeaders });
  }
  getFilteredOrcamentosREVIEW(cod_condominio: string, startYear: Date, endYear: Date, tipoSel: string, page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    let startYearStr = (startYear) ? startYear.getFullYear().toString() : 'NULL';
    let endYearStr = (endYear) ? endYear.getFullYear().toString() : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/orcamentos/getfilteredlistREVIEW/' + cod_condominio + '/' + startYearStr + '/' + endYearStr + '/' + tipoSel + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getOrcamentoDetails(id): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/getdetails/' + id, { headers: this.authHeaders });
  }
  getOrcamentoDetailsSimple(id): Observable<interfaces.getOrcamentoApi> {
    return this.http.get<interfaces.getOrcamentoApi>(this.baseUrl + '/orcamentos/getdetailssimple/' + id, { headers: this.authHeaders });
  }
  getOrcamentoNextDescricao(cod_condominio: string, exercicio: string): Observable<interfaces.defaultStringApiRequest> {
    return this.http.get<interfaces.defaultStringApiRequest>(this.baseUrl + '/orcamentos/getnextdescricao/' + cod_condominio + '/' + exercicio, { headers: this.authHeaders });
  }
  getOrcamentoDetailsByCond(condominioCod): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/getdetailsbycondominio/' + condominioCod, { headers: this.authHeaders });
  }
  getOrcamentos(page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/orcamentos/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getFilteredOrcamentos(exercicio: string, page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/orcamentos/getfilteredlist/' + exercicio + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getOrcamentoByPeriodoAndAprovado(cod_condominio: number, lancado = false, periodo = null): Observable<{ success, data: { orcamento: Orcamento } }> {
    let lancadoParam = lancado ? '1' : '0';
    periodo = (periodo) ? periodo : 'NULL';
    return this.http.get<{ success, data: { orcamento: Orcamento } }>(this.baseUrl + '/orcamentos/getbyperiodoandaprovado/' + cod_condominio + '/' + periodo + '/' + lancadoParam, { headers: this.authHeaders });
  }
  getOrcExerciciosByCondominio(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/getexercicios/' + cod_condominio, { headers: this.authHeaders });
  }
  getFracaoQuota(cod_condominio, cod_fraccao, date: Date = null): Observable<interfaces.defaultStringApiRequest> {
    let url = new URL(this.baseUrl + '/orcamentos/getfracaoquota/' + cod_condominio + '/' + cod_fraccao);
    if (date) url.searchParams.set('date', this.utils.getFormatedDate(date));
    return this.http.get<interfaces.defaultStringApiRequest>(url.toString(), { headers: this.authHeaders });
  }
  getFracaoFutureQuotaExtra(cod_condominio, cod_fraccao, date: Date = null): Observable<interfaces.getOrcamentoApi> {
    let url = new URL(this.baseUrl + '/processamentos/getfuturequotasextra/' + cod_condominio + '/' + cod_fraccao + '/' + this.utils.getFormatedDate(date));
    return this.http.get<interfaces.getOrcamentoApi>(url.toString(), { headers: this.authHeaders });
  }

  // Create
  saveOrcamentoREVIEW(id, cod, cod_condominio, periodo, descricao, dt_inicio, dt_fim, orcZonas, orcRubricas, orcFraccoes, orcFraccoesFr, rubricasToDelete = []): Observable<any> {
    let body = {
      id: id,
      cod: cod,
      cod_condominio: cod_condominio,
      periodo: periodo,
      descricao: descricao,
      dt_inicio: dt_inicio,
      dt_fim: dt_fim,

      orc_zonas_list: orcZonas,
      orc_rubricas_list: orcRubricas,
      orc_fraccoes_list: orcFraccoes,
      orc_fraccoes_fr_list: orcFraccoesFr,

      rubricasToDelete: rubricasToDelete,
    }
    return this.http.post(this.baseUrl + '/orcamentos/saveorcamentoREVIEW', body, { headers: this.authHeaders });
  }
  copyOrcamento(copiedID: string, newExercicio: string = null): Observable<interfaces.copyOrcamentoApi> {
    let body = {
      id: copiedID,
      newExercicio: newExercicio
    }
    return this.http.post<interfaces.copyOrcamentoApi>(this.baseUrl + '/orcamentos/copyorcamento', body, { headers: this.authHeaders });
  }

  //Delete
  delOrcamentos(list): Observable<any> {
    let body = {
      list: list,
      nowDate: new Date(),
    }
    return this.http.post(this.baseUrl + '/orcamentos/delete', body, { headers: this.authHeaders });
  }

  // Utilities
  checkPreviousOrcamentoByCondominio(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/checklastorcamento/' + cod_condominio, { headers: this.authHeaders });
  }
  hasOrcamentoLancado(cod_condominio, exercicio): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/hasorcamentolancado/' + cod_condominio + '/' + exercicio, { headers: this.authHeaders });
  }
  isRubricaOrcamentada(cod_condominio, exercicio, mes, cod_rubrica): Observable<any> {
    return this.http.get(this.baseUrl + '/orcamentos/isrubricaorcamentada/' + cod_condominio + '/' + exercicio + '/' + mes + '/' + cod_rubrica, { headers: this.authHeaders });
  }



  // --------------------- ORCAMENTOS ENTITY - END


  // --------------------- ESTADO CONTENCIOSO ENTITY - START
  getEstadosContencioso(): Observable<getEstadosContenciososAPI> {
    return this.http.get<getEstadosContenciososAPI>(this.baseUrl + '/estadoscontenciosos/getlist', { headers: this.authHeaders });
  }
  getEstadoContencioso(id): Observable<getEstadoContenciosoAPI> {
    return this.http.get<getEstadoContenciosoAPI>(this.baseUrl + '/estadoscontenciosos/getdetails/' + id, { headers: this.authHeaders });
  }
  getContenciososNotConfiguredOptions(): Observable<getContenciososApi> {
    return this.http.get<getContenciososApi>(this.baseUrl + '/estadoscontenciososnotconfigured/getoptions', { headers: this.authHeaders });
  }
  getAllEstadosContenciosos(filterContenciosos: Array<string>): Observable<any> {
    // filterContenciosos 
    let url = new URL(this.baseUrl + '/estadoscontenciosos/condominos');
    filterContenciosos.forEach(contencioso => {
      url.searchParams.append('estados[]', contencioso);
    })
    return this.http.get<any>(url.toString(), { headers: this.authHeaders });
  }
  // --------------------- ESTADO CONTENCIOSO ENTITY - END


  // --------------------- RUBRICAS ENTITY - START
  getRubricas(): Observable<any> {
    return this.http.get(this.baseUrl + '/rubricas/getlist', { headers: this.authHeaders });
  }
  delRubricas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/rubricas/delete', body, { headers: this.authHeaders });
  }
  addRubrica(nome, n_ordem, despesa, receita, val_fixo): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
      despesa: despesa,
      receita: receita,
      val_fixo: val_fixo
    }
    return this.http.post(this.baseUrl + '/rubricas/add', body, { headers: this.authHeaders });
  }
  updateRubrica(id, nome, n_ordem, despesa, receita, val_fixo): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
      despesa: despesa,
      receita: receita,
      val_fixo: val_fixo
    }
    return this.http.post(this.baseUrl + '/rubricas/update', body, { headers: this.authHeaders });
  }
  getAllRubricas(keyword: string = 'NULL'): Observable<any> {
    return this.http.get(this.baseUrl + '/rubricas/getall/20/' + keyword, { headers: this.authHeaders });
  }
  // --------------------- RUBRICAS ENTITY - END


  // --------------------- ASSEMBLEIAS CONFIG ENTITY - START
  getOrdemTrabalhos(): Observable<any> {
    return this.http.get(this.baseUrl + '/ordemtrabalhos/getlist', { headers: this.authHeaders });
  }
  updateOrdemTrabalho(id, descricao, ordem, ordinarias_predefinido, extras_predefinido, ordinarias_diversos_predefinido, extras_diversos_predefinido, is_ordem_trabalho, is_assunto_diverso, is_multipla_entrada, texto_inicial, texto_final, has_anexo, tipo_anexo_id, respostas, predefinidoOrdinarias, predefinidoExtraordinarias): Observable<any> {
    let body = {
      id: id,
      descricao: descricao,
      ordem: ordem,
      ordinarias_predefinido: (ordinarias_predefinido) ? 1 : 0,
      extras_predefinido: (extras_predefinido) ? 1 : 0,
      ordinarias_diversos_predefinido: (ordinarias_diversos_predefinido) ? 1 : 0,
      extras_diversos_predefinido: (extras_diversos_predefinido) ? 1 : 0,
      is_ordem_trabalho: (is_ordem_trabalho) ? 1 : 0,
      is_assunto_diverso: (is_assunto_diverso) ? 1 : 0,
      is_multipla_entrada: (is_multipla_entrada) ? 1 : 0,
      has_anexo: (has_anexo) ? 1 : 0,
      texto_inicial: texto_inicial,
      texto_final: texto_final,
      tipo_anexo_id: tipo_anexo_id,
      respostas: respostas.map(el => {
        el.default = (el.default) ? 1 : 0;
        return el;
      }),
      predefinidoOrdinarias: predefinidoOrdinarias,
      predefinidoExtraordinarias: predefinidoExtraordinarias,
    }
    return this.http.post(this.baseUrl + '/ordemtrabalhos/update', body, { headers: this.authHeaders });
  }
  delOrdemTrabalho(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/ordemtrabalhos/delete', body, { headers: this.authHeaders });
  }
  updateOrdemTrabalhoResp(id, id_ordem_trabalho, descricao, modelo_texto, has_anexo, tipo_anexo_id, obj = null, hasEmail, emailId, hasCarta, cartaId): Observable<any> {
    let body = {
      id: id,
      descricao: descricao,
      id_ordem_trabalho: id_ordem_trabalho,
      modelo_texto: modelo_texto,
      tipo_anexo_id: tipo_anexo_id,
      has_anexo: (has_anexo) ? '1' : '0',
      hasEmail: (hasEmail) ? '1' : '0',
      emailId: emailId,
      hasCarta: (hasEmail) ? '1' : '0',
      cartaId: cartaId,
      obj: (obj) ? JSON.stringify(obj) : null,
    }
    return this.http.post(this.baseUrl + '/ordemtrabalhosresp/save', body, { headers: this.authHeaders });
  }
  delOrdemTrabRespostas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/ordemtrabalhosresp/delete', body, { headers: this.authHeaders });
  }
  getOrdemTrabalhosResp(query: { ordem_trabalho_label?} = {}): Observable<any> {
    let url = new URL(this.baseUrl + '/ordemtrabalhosresp/getlist');
    if (query.hasOwnProperty('ordem_trabalho_label')) {
      url.searchParams.set('ordem_trabalho_label', query.ordem_trabalho_label);
    }
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  getAssembDocsModels(): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleiadocs/getlist', { headers: this.authHeaders });
  }
  updateAssembDocsModels(id, nome, texto_inicial, texto_final): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      texto_inicial: texto_inicial,
      texto_final: texto_final
    }
    return this.http.post(this.baseUrl + '/assembleiadocs/update', body, { headers: this.authHeaders });
  }


  // delRubricas(list): Observable<any> {
  //   let body = {
  //     list: list,
  //   }
  //   return this.http.post(this.baseUrl + '/rubricas/delete', body, { headers: this.authHeaders });
  // }
  // addRubrica(nome, n_ordem, despesa, receita): Observable<any> {
  //   let body = {
  //     nome: nome,
  //     n_ordem: n_ordem,
  //     despesa: despesa,
  //     receita: receita
  //   }
  //   return this.http.post(this.baseUrl + '/rubricas/add', body, { headers: this.authHeaders });
  // }
  // updateRubrica(id, nome, n_ordem, despesa, receita): Observable<any> {
  //   let body = {
  //     id: id,
  //     nome: nome,
  //     n_ordem: n_ordem,
  //     despesa: despesa,
  //     receita: receita
  //   }
  //   return this.http.post(this.baseUrl + '/rubricas/update', body, { headers: this.authHeaders });
  // }
  // getAllRubricas(keyword: string = 'NULL'): Observable<any> {
  //   return this.http.get(this.baseUrl + '/rubricas/getall/20/' + keyword, { headers: this.authHeaders });
  // }
  // --------------------- RUBRICAS ENTITY - END

  // --------------------- TIPO EQUIPAMENTO ENTITY - START
  getTipoEquipamentos(): Observable<any> {
    return this.http.get(this.baseUrl + '/tipoequipamentos/getlist', { headers: this.authHeaders });
  }
  delTipoEquipamentos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tipoequipamentos/delete', body, { headers: this.authHeaders });
  }
  addTipoEquipamento(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipoequipamentos/add', body, { headers: this.authHeaders });
  }
  updateTipoEquipamento(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipoequipamentos/update', body, { headers: this.authHeaders });
  }
  // --------------------- TIPO EQUIPAMENTO ENTITY - END


  // --------------------- TIPO INTERVENCAO ENTITY - START
  getTipoIntervencoes(): Observable<any> {
    return this.http.get(this.baseUrl + '/tipointervencoes/getlist', { headers: this.authHeaders });
  }
  delTipoIntervencoes(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tipointervencoes/delete', body, { headers: this.authHeaders });
  }
  addTipoIntervencao(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipointervencoes/add', body, { headers: this.authHeaders });
  }
  updateTipoIntervencao(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipointervencoes/update', body, { headers: this.authHeaders });
  }
  // --------------------- TIPO INTERVENCAO ENTITY - END


  // --------------------- ACCOES ENTITY - START
  getAccoes(): Observable<any> {
    return this.http.get(this.baseUrl + '/accoes/getlist');
  }
  delAccoes(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/accoes/delete', body, { headers: this.authHeaders });
  }
  addAccao(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/accoes/add', body, { headers: this.authHeaders });
  }
  updateAccao(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/accoes/update', body, { headers: this.authHeaders });
  }
  // --------------------- ACCOES ENTITY - END


  // --------------------- BANCOS ENTITY - START
  getBancos(): Observable<any> {
    return this.http.get(this.baseUrl + '/bancos/getlist', { headers: this.authHeaders });
  }
  delBancos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/bancos/delete', body, { headers: this.authHeaders });
  }
  addBanco(banco, sigla): Observable<any> {
    let body = {
      banco: banco,
      sigla: sigla,
    }
    return this.http.post(this.baseUrl + '/bancos/add', body, { headers: this.authHeaders });
  }
  updateBanco(id, banco, sigla): Observable<any> {
    let body = {
      id: id,
      banco: banco,
      sigla: sigla,
    }
    return this.http.post(this.baseUrl + '/bancos/update', body, { headers: this.authHeaders });
  }
  getAllBancos(keyword: string = 'NULL', all = false): Observable<any> {
    if (all) {
      return this.http.get(this.baseUrl + '/bancos/getall/ALL/' + keyword, { headers: this.authHeaders });
    } else {
      return this.http.get(this.baseUrl + '/bancos/getall/20/' + keyword, { headers: this.authHeaders });
    }
  }
  // --------------------- BANCOS ENTITY - END


  // --------------------- SERVICOS ENTITY - START
  getServicos(): Observable<any> {
    return this.http.get(this.baseUrl + '/tipos/getlist', { headers: this.authHeaders });
  }
  delServicos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tipos/delete', body, { headers: this.authHeaders });
  }
  addServicos(descricao, n_ordem): Observable<any> {
    let body = {
      descricao: descricao,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipos/add', body, { headers: this.authHeaders });
  }
  updateServicos(id, descricao, n_ordem): Observable<any> {
    let body = {
      id: id,
      descricao: descricao,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipos/update', body, { headers: this.authHeaders });
  }
  // --------------------- SERVICOS ENTITY - END


  // --------------------- TIPO OCORRENCIAS ENTITY - START
  getTipoOcorrencias(): Observable<any> {
    return this.http.get(this.baseUrl + '/tipoocorrencias/getlist', { headers: this.authHeaders });
  }
  delTipoOcorrencias(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tipoocorrencias/delete', body, { headers: this.authHeaders });
  }
  addTipoOcorrencia(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipoocorrencias/add', body, { headers: this.authHeaders });
  }
  updateTipoOcorrencia(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipoocorrencias/update', body, { headers: this.authHeaders });
  }
  // --------------------- TIPO OCORRENCIAS ENTITY - END


  // --------------------- TEXTOS ENTITY - START
  getTextosOptions(): Observable<any> {
    return this.http.get(this.baseUrl + '/textos/getoptionlist', { headers: this.authHeaders });
  }
  getTextos(): Observable<getTextosApi> {
    return this.http.get<getTextosApi>(this.baseUrl + '/textos/getlist', { headers: this.authHeaders });
  }
  getTextosDetails(cod): Observable<any> {
    return this.http.get(this.baseUrl + '/textos/getdetails/' + cod, { headers: this.authHeaders });
  }
  getTextoFilledToCommunicate(id_texto, cod_proprietario, id_fracao, fillingParams: { advogado, agente_execucao } = null): Observable<getTextoFilledToCommunicateApi> {
    let url = new URL(this.baseUrl + '/textos/getfilledwithparams/' + id_texto + '/' + cod_proprietario + '/' + id_fracao);
    if (fillingParams) {
      if (fillingParams.advogado) url.searchParams.set('advogado', fillingParams.advogado);
      if (fillingParams.agente_execucao) url.searchParams.set('agente_execucao', fillingParams.agente_execucao);
    }

    return this.http.get<getTextoFilledToCommunicateApi>(url.toString(), { headers: this.authHeaders });
  }
  delTextos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/textos/delete', body, { headers: this.authHeaders });
  }
  addTexto(nome, tipo, assunto, text_inicial, texto_final): Observable<any> {
    let body = {
      nome: nome,
      tipo: tipo,
      assunto: assunto,
      text_inicial: text_inicial,
      texto_final: texto_final
    }
    return this.http.post(this.baseUrl + '/textos/add', body, { headers: this.authHeaders });
  }
  updateTexto(id, nome, tipo, assunto, text_inicial, texto_final): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      tipo: tipo,
      assunto: assunto,
      text_inicial: text_inicial,
      texto_final: texto_final
    }
    return this.http.post(this.baseUrl + '/textos/update', body, { headers: this.authHeaders });
  }
  // --------------------- TEXTOS ENTITY - END


  // --------------------- TIPO CONTAS ENTITY - START
  getTipoContas(): Observable<any> {
    return this.http.get(this.baseUrl + '/tipocontas/getlist', { headers: this.authHeaders });
  }
  delTipoContas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tipocontas/delete', body, { headers: this.authHeaders });
  }
  addTipoContas(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipocontas/add', body, { headers: this.authHeaders });
  }
  updateTipoContas(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/tipocontas/update', body, { headers: this.authHeaders });
  }
  // --------------------- TIPO CONTAS ENTITY - END

  // --------------------- TIPOS APP ENTITY - START
  getAllTiposAppGet(keyword: string = 'NULL', tipo = 'FRACCAO_DESCRICAO'): Observable<any> {
    return this.http.get(this.baseUrl + '/tiposapp/getall/50/' + tipo + '/' + keyword, { headers: this.authHeaders });
  }
  getAllTiposApp(keyword: string = 'NULL', tipo = 'FRACCAO_DESCRICAO'): Observable<any> {
    let body = {
      tipo: tipo,
      keyword: keyword,
    }
    return this.http.post(this.baseUrl + '/tiposapp/getall/50', body, { headers: this.authHeaders });
  }
  addTipoApp(nome, tipo): Observable<any> {
    let body = {
      nome: nome,
      tipo: tipo,
    }
    return this.http.post(this.baseUrl + '/tiposapp/add', body, { headers: this.authHeaders });
  }
  // --------------------- TIPOS APP ENTITY - END



  // --------------------- DOCS ASSEMBLEIAS ENTITY - START
  getAssembDocs(): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleiadocs/getlist', { headers: this.authHeaders });
  }
  delAssembDocs(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/assembleiadocs/delete', body, { headers: this.authHeaders });
  }
  addAssembDocs(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/assembleiadocs/add', body, { headers: this.authHeaders });
  }
  updateAssembDocs(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/assembleiadocs/update', body, { headers: this.authHeaders });
  }
  // --------------------- DOCS ASSEMBLEIAS ENTITY - END

  // --------------------- REG TRABALHOS ASSEMBLEIAS ENTITY - START
  getAssembRegTrab(): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleiaregtrab/getlist', { headers: this.authHeaders });
  }
  delAssembRegTrab(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/assembleiaregtrab/delete', body, { headers: this.authHeaders });
  }
  addAssembRegTrab(nome, n_ordem): Observable<any> {
    let body = {
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/assembleiaregtrab/add', body, { headers: this.authHeaders });
  }
  updateAssembRegTrab(id, nome, n_ordem): Observable<any> {
    let body = {
      id: id,
      nome: nome,
      n_ordem: n_ordem,
    }
    return this.http.post(this.baseUrl + '/assembleiaregtrab/update', body, { headers: this.authHeaders });
  }
  // --------------------- REG TRABALHOS ASSEMBLEIAS ENTITY - END






  // --------------------- PROCESSAMENTOS ENTITY - START
  getProcessamentos(cod_condominio, exercicio, page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null, query: GetProcessamentoListQuery = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    let limit_str = (limit) ? limit : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    exercicio = (exercicio) ? exercicio : 'NULL';
    let url = new URL(this.baseUrl + '/processamentos/getlist/' + cod_condominio + '/' + exercicio + '/' + + page + '/' + limit_str + '/' + keyword + '/' + sortParam + '/' + sortDirection);
    if (query != null) {
      if (query.hasOwnProperty('isLancado') && query.isLancado !== null) url.searchParams.append('isLancado', query.isLancado.toString());
      if (query.hasOwnProperty('tipo_proc') && query.tipo_proc !== null) url.searchParams.append('tipo_proc', query.tipo_proc);
      if (query.hasOwnProperty('startYear') && query.startYear !== null) url.searchParams.append('startYear', query.startYear.toString());
      if (query.hasOwnProperty('endYear') && query.endYear !== null) url.searchParams.append('endYear', query.endYear.toString());
    }

    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  getProcessamentoDetails(id: number, queryParams: { includeRecibos: boolean } = null): Observable<any> {
    let url = new URL(this.baseUrl + '/processamentos/getdetails/' + id);
    if (queryParams) {
      if (queryParams.hasOwnProperty('includeRecibos')) {
        url.searchParams.append('includeRecibos', (queryParams.includeRecibos == true) ? '1' : '0');
      }
    }
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  getProcessamentoDetailsToPrint(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/processamentos/printinfo/' + id, { headers: this.authHeaders });
  }
  getAvisosCreditosByProcessamento(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/processamentos/getavisosecreditos/' + id, { headers: this.authHeaders });
  }
  getAvisosByProcessamento(cod_proc): Observable<any> {
    return this.http.get(this.baseUrl + '/processamentos/getavisos/' + cod_proc, { headers: this.authHeaders });
  }
  getProcessamentoRecibos(codigos: Array<string | number>): Observable<any> {
    let url = new URL(this.baseUrl + '/processamentos/recibos');
    codigos.forEach(cod => {
      url.searchParams.append('cods_processamento[]', cod.toString())
    })
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  addProcessamentos(orcamento_id, list, orcFraccoes = [], id_act_seg = null, now = new Date()): Observable<any> {
    // cod,mes,ano,cod_condominio,exercicio,tipo_proc,data,data_venc,descricao,pluri_anual,nome,valor
    let body = {
      orcamento_id: orcamento_id,
      cod_condominio: list[0]['cod_condominio'],
      list: list,
      orc_fraccoes_list: orcFraccoes,
      id_act_seg: id_act_seg,
      now: now,
    }
    return this.http.post(this.baseUrl + '/processamentos/add', body, { headers: this.authHeaders });
  }
  saveProcessamento(data: SaveProcessamento): Observable<interfaces.defaultNumberApiRequest> {
    let body = data
    return this.http.post<interfaces.defaultNumberApiRequest>(this.baseUrl + '/processamentos/save', body, { headers: this.authHeaders });
  }
  lancarProcessamento(data: SaveProcessamento): Observable<interfaces.defaultNumberApiRequest> {
    let body = data
    return this.http.post<interfaces.defaultNumberApiRequest>(this.baseUrl + '/processamentos/lancar', body, { headers: this.authHeaders });
  }
  updateProcessamento(id, cod, descricao): Observable<any> {
    let body = {
      id: id,
      cod: cod,
      descricao: descricao,
    }
    return this.http.post(this.baseUrl + '/processamentos/update', body, { headers: this.authHeaders });
  }
  copyProcessamento(id: number): Observable<any> {
    let body = {
      id: id
    }
    return this.http.post(this.baseUrl + '/processamentos/copy', body, { headers: this.authHeaders });
  }
  delProcessamentos(ids: Array<number>, delType: DelType): Observable<any> {
    let body = {
      list: ids,
      delType: delType
    }
    return this.http.post(this.baseUrl + '/processamentos/delete', body, { headers: this.authHeaders });
  }
  hasSimilarProcessamento(query: { tipo_proc: string, cod_condominio: number, valor: number, data_inicio: Date, data_fim: Date } | { id_processamento: string }): Observable<any> {
    let url = new URL(this.baseUrl + '/processamentos/similar');
    if (!query
      || ((!query.hasOwnProperty('cod_condominio')
        || !query.hasOwnProperty('valor')
        || !query.hasOwnProperty('data_inicio')
        || !query.hasOwnProperty('tipo_proc')
        || !query.hasOwnProperty('data_fim')
      )
        &&
        (
          !query.hasOwnProperty('id_processamento')
        )
      )) {
      return;
    }
    if ('id_processamento' in query) {
      url.searchParams.append('id_processamento', query.id_processamento);
    } else {
      url.searchParams.append('tipo_proc', query.tipo_proc);
      url.searchParams.append('cod_condominio', query.cod_condominio.toString());
      url.searchParams.append('valor', query.valor.toString());
      url.searchParams.append('data_inicio', this.utils.getFormatedDate(query.data_inicio));
      url.searchParams.append('data_fim', this.utils.getFormatedDate(query.data_fim));
    }
    return this.http.get(url.toString(), { headers: this.authHeaders });
  }
  setProcessamentosPenalizacoes(cod_condominio = null): Observable<any> {
    let now = this.utils.getFormatedDate(new Date());
    return this.http.get(this.baseUrl + '/processamentos/managepenalizacoes/' + cod_condominio + '/' + now, { headers: this.authHeaders });
  }
  // --------------------- PROCESSAMENTOS ENTITY - END










  // --------------------- AVISOS ENTITY - START
  getAvisos(cod_condominio, exercicio, page: number, limit: number, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/avisos/getlist/' + cod_condominio + '/' + exercicio + '/' + + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getAvisosRecebimentos(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/avisos/getrecebimentoslist/' + cod_condominio, { headers: this.authHeaders });
  }
  saveAviso(id, cod, n_aviso, cod_condominio, cod_fraccao, cod_pagador, cod_proc, cod_zona, debito, descricao, dt_emissao, dt_vencimento, mes, tipo_proc, valor, ano): Observable<any> {
    let body = {
      id: id,
      cod: cod,
      n_aviso: n_aviso,
      cod_condominio: cod_condominio,
      cod_fraccao: cod_fraccao,
      cod_pagador: cod_pagador,
      cod_proc: cod_proc,
      cod_zona: cod_zona,
      debito: debito,
      descricao: descricao,
      dt_emissao: (dt_emissao) ? this.utils.getFormatedDate(dt_emissao) : null,
      dt_vencimento: (dt_vencimento) ? this.utils.getFormatedDate(dt_vencimento) : null,
      mes: mes,
      tipo_proc: tipo_proc,
      valor: valor,
      ano: ano,
    }
    return this.http.post(this.baseUrl + '/avisos/save', body, { headers: this.authHeaders });
  }
  getAvisoDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/avisos/getdetails/' + id, { headers: this.authHeaders });
  }
  delAvisos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/avisos/delete', body, { headers: this.authHeaders });
  }
  changeAvisos(fraccaoCod, condominioCod, proprietarioCod, proprietariosList, avisosLimitArr, updateAvisos): Observable<any> {
    let body = {
      cod_condominio: condominioCod,
      cod_fraccao: fraccaoCod,
      cod_pagador: proprietarioCod,
      avisos_limit_list: avisosLimitArr,
      proprietarios_list: JSON.stringify(proprietariosList),
      update_avisos: updateAvisos,
    }
    return this.http.post(this.baseUrl + '/avisos/changeproprietario', body, { headers: this.authHeaders });
  }
  getFirstAviso(codProprietario): Observable<any> {
    return this.http.get(this.baseUrl + '/avisos/getfirstaviso/' + codProprietario, { headers: this.authHeaders });
  }
  getFirstAvisoByProprietarios(codCondominio, codFraccao): Observable<any> {
    return this.http.get(this.baseUrl + '/avisos/getfirstavisobyproprietario/' + codCondominio + '/' + codFraccao, { headers: this.authHeaders });
  }
  // --------------------- AVISOS ENTITY - END


  // --------------------- MOVIMENTOS ENTITY - START
  getMovimentos(nid_conta, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = 'DESC'): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    // sortDirection = (sortDirection) ? sortDirection : 'NULL';
    sortDirection = sortDirection;

    start_date = (start_date) ? this.utils.getFormatedDate(start_date) : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date) : 'NULL';

    return this.http.get(this.baseUrl + '/movimentos/getlist/' + nid_conta + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getSaldoMovimentos(nid_conta, end_date = null): Observable<any> {
    end_date = (end_date) ? this.utils.getFormatedDate(end_date) : 'NULL';

    return this.http.get(this.baseUrl + '/movimentos/getsaldo/' + nid_conta + '/' + end_date, { headers: this.authHeaders });
  }
  getFirstMovimento(nid_conta): Observable<any> {
    return this.http.get(this.baseUrl + '/movimentos/getfirstmovimento/' + nid_conta, { headers: this.authHeaders });
  }
  getMovimentosEmCaixa(date = (new Date())): Observable<any> {
    date = (date) ? this.utils.getFormatedDate(date) : 'NULL';

    return this.http.get(this.baseUrl + '/movimentos/getlistemcaixa/' + date, { headers: this.authHeaders });
  }
  getMovimentoDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/movimentos/getdetails/' + id, { headers: this.authHeaders });
  }
  delMovimentos(list, target = null): Observable<any> {
    let body = {
      list: list,
    }
    switch (target) {
      case 'DESPESAS':
        return this.http.post(this.baseUrl + '/movimentos/delete/despesas', body, { headers: this.authHeaders });
        break;
      case 'RECEITAS':
        return this.http.post(this.baseUrl + '/movimentos/delete/receitas', body, { headers: this.authHeaders });
        break;
      default:
        return this.http.post(this.baseUrl + '/movimentos/delete', body, { headers: this.authHeaders });

    }
  }
  saveMovimentosList(from, list, toDeleteList = []): Observable<any> {
    // dt_mov,valor,dt_valor,nid_rubrica,nid_conta,descricao
    let body = {
      from: from,
      list: list,
      toDeleteList: toDeleteList,
    }
    return this.http.post(this.baseUrl + '/movimentos/savelist', body, { headers: this.authHeaders });
  }
  saveMovimento(id, descricao): Observable<any> {
    let body = {
      id: id,
      descricao: descricao,
    }
    return this.http.post(this.baseUrl + '/movimentos/save', body, { headers: this.authHeaders });
  }
  canChangeMovimento(id_mov): Observable<interfaces.defaultStringApiRequest> {
    return this.http.get<interfaces.defaultStringApiRequest>(this.baseUrl + '/movimentos/canchange/' + id_mov.toString(), { headers: this.authHeaders });
  }
  //Reconciliações Bancárias
  getUltimaReconciliacao(cod_conta: string): Observable<interfaces.reconciliacaoBancariaBDApi> {
    return this.http.get<interfaces.reconciliacaoBancariaBDApi>(this.baseUrl + '/reconciliacoesbancarias/getlastmovreconciliado/' + cod_conta, { headers: this.authHeaders });
  }
  getReconciliacoesBancarias(cod_conta: string, includeCurrent = false): Observable<interfaces.reconciliacaoBancariaBDDetailedApiArray> {
    let include = includeCurrent ? '1' : '0';
    return this.http.get<interfaces.reconciliacaoBancariaBDDetailedApiArray>(this.baseUrl + '/reconciliacoesbancarias/get/' + cod_conta + '/' + include, { headers: this.authHeaders });
  }
  getReconciliacoesBancariasRegAtividade(cod_conta: string, startDate: Date, endDate: Date, actionType: string): Observable<interfaces.reconciliacaoBancariaRegAtividadeBDArrayApi> {
    let start_date = startDate ? this.utils.getFormatedDate(startDate) : 'NULL';
    let end_date = startDate ? this.utils.getFormatedDate(endDate) : 'NULL';
    return this.http.get<interfaces.reconciliacaoBancariaRegAtividadeBDArrayApi>(this.baseUrl + '/reconciliacoesbancarias/getregistoatividade/' + cod_conta + '/' + start_date + '/' + end_date + '/' + actionType, { headers: this.authHeaders });
  }
  getReconciliacaoEmCurso(cod_conta: string): Observable<interfaces.reconciliacaoBancariaBDDetailedApi> {
    return this.http.get<interfaces.reconciliacaoBancariaBDDetailedApi>(this.baseUrl + '/reconciliacoesbancarias/getemcurso/' + cod_conta, { headers: this.authHeaders });
  }
  startReconciliacaoBancaria(cod_conta: string, data: Date, saldo: string): Observable<interfaces.reconciliacaoBancariaBDApi> {
    let body = {
      cod_conta: cod_conta,
      data: data ? this.utils.getFormatedDate(data) : null,
      saldo: saldo ? parseFloat(saldo) : null,
    }
    return this.http.post<interfaces.reconciliacaoBancariaBDApi>(this.baseUrl + '/reconciliacoesbancarias/start', body, { headers: this.authHeaders });
  }
  cancelReconciliacaoBancaria(cod_conta: string, delRazao: string): Observable<interfaces.reconciliacaoBancariaRecibosPorEnviarApi> {
    let body = {
      cod_conta: cod_conta,
      delRazao: delRazao,
    }
    return this.http.post<interfaces.reconciliacaoBancariaRecibosPorEnviarApi>(this.baseUrl + '/reconciliacoesbancarias/cancel', body, { headers: this.authHeaders });
  }
  checkMovimentoReconciliacao(cod_conta: string, id_mov: string, reconciliado: boolean): Observable<interfaces.reconciliacaoBancariaBDApi> {
    let body = {
      cod_conta: cod_conta,
      id_mov: id_mov,
      reconciliado: reconciliado ? '1' : '0'
    }
    return this.http.post<interfaces.reconciliacaoBancariaBDApi>(this.baseUrl + '/reconciliacoesbancarias/checkmovimento', body, { headers: this.authHeaders });
  }
  setReconciliacao(id_reconciliacao, data_reconciliacao: Date, extrato_file_base64, extrato_file_ext): Observable<interfaces.reconciliacaoBancariaRecibosPorEnviarApi> {
    let body = {
      id_reconciliacao: id_reconciliacao,
      data_reconciliacao: data_reconciliacao ? this.utils.getFormatedDate(data_reconciliacao) : null,
      extrato_file_base64: extrato_file_base64,
      extrato_file_ext: extrato_file_ext
    }
    return this.http.post<interfaces.reconciliacaoBancariaRecibosPorEnviarApi>(this.baseUrl + '/reconciliacoesbancarias/setreconciliacao', body, { headers: this.authHeaders });
  }
  revertReconciliacao(cod_conta, id_mov): Observable<interfaces.defaultBooleanApiRequest> {
    let body = {
      cod_conta: cod_conta,
      id_mov: id_mov,
    }
    return this.http.post<interfaces.defaultBooleanApiRequest>(this.baseUrl + '/reconciliacoesbancarias/revertreconciliacao', body, { headers: this.authHeaders });
  }
  canRevertReconciliacao(cod_conta, id_mov): Observable<interfaces.defaultBooleanApiRequest> {
    let body = {
      cod_conta: cod_conta,
      id_mov: id_mov,
    }
    return this.http.post<interfaces.defaultBooleanApiRequest>(this.baseUrl + '/reconciliacoesbancarias/canrevertreconciliacao', body, { headers: this.authHeaders });
  }
  getReconciliacaoEmCursoFinalData(id_reconciliacao): Observable<interfaces.reconciliacaoBancariaFinalDataApi> {
    return this.http.get<interfaces.reconciliacaoBancariaFinalDataApi>(this.baseUrl + '/reconciliacoesbancarias/getreconciliacaoemcursofinaldata/' + id_reconciliacao, { headers: this.authHeaders });
  }
  isMovementInReconciliacaoEmCurso(cod_conta, id_mov, dt_mov): Observable<interfaces.isMovementInReconciliacaoEmCursoApi> {
    let body = {
      cod_conta: cod_conta,
      id_mov: id_mov,
      dt_mov: dt_mov,
    }
    return this.http.post<interfaces.isMovementInReconciliacaoEmCursoApi>(this.baseUrl + '/reconciliacoesbancarias/ismovementinreconciliacaoemcurso', body, { headers: this.authHeaders });
  }
  getRecibosPorEnviar(id_reconciliacao): Observable<interfaces.reconciliacaoBancariaRecibosPorEnviarApi> {
    let body = {
      id_reconciliacao: id_reconciliacao,
    }
    return this.http.post<interfaces.reconciliacaoBancariaRecibosPorEnviarApi>(this.baseUrl + '/reconciliacoesbancarias/getrecibosporenviar', body, { headers: this.authHeaders });
  }
  getRecibosPorEnviarCartaDetails(id_reconciliacao): Observable<interfaces.reconciliacaoBancariaRecibosPorEnviarApi> {
    let body = {
      id_reconciliacao: id_reconciliacao,
    }
    return this.http.post<interfaces.reconciliacaoBancariaRecibosPorEnviarApi>(this.baseUrl + '/reconciliacoesbancarias/getrecibosporenviarcartadetails', body, { headers: this.authHeaders });
  }
  communicateAvisosECobrancasReconciliacao(id_reconciliacao): Observable<interfaces.defaultBooleanApiRequest> {
    let body = {
      id_reconciliacao: id_reconciliacao,
    }
    return this.http.post<interfaces.defaultBooleanApiRequest>(this.baseUrl + '/reconciliacoesbancarias/communicateavisosecobrancas', body, { headers: this.authHeaders });
  }
  // --------------------- MOVIMENTOS ENTITY - END


  // --------------------- DESPESAS ENTITY - START
  getDespesas(cod_condominio, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    return this.http.get(this.baseUrl + '/despesas/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getDespesasByFornecedor(cod_fornecedor, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/despesas/getlistbyfornecedor/' + cod_fornecedor + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }

  getDespesaDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/despesas/getdetails/' + id, { headers: this.authHeaders });
  }
  getDespesaFiles(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/despesas/getfiles/' + id, { headers: this.authHeaders });
  }

  getAgendDespesaDetails(cod_condominio, n_agendamento): Observable<{ success, data: { despesas, agendamento, reg_act } }> {
    return this.http.get<{ success, data: { despesas, agendamento, reg_act } }>(this.baseUrl + '/despesas/getagendamentodetails/' + cod_condominio + '/' + n_agendamento, { headers: this.authHeaders });
  }
  getAgendDespesaMov(cod_condominio, n_agendamento): Observable<any> {
    return this.http.get(this.baseUrl + '/despesas/getagendamentomovimentos/' + cod_condominio + '/' + n_agendamento, { headers: this.authHeaders });
  }
  saveDespesas(condominio_list, cod_rubrica, cod_fornecedor, tipo_proc, dt_desp, dt_pag_valor, dt_pag, dt_venc, valor, valor_pago, n_documento, form_pagam, cod_conta_bancaria, descricao, n_doc_pagam, ref_interna, nid_movimento, reparticao, obj, tipoDespesa = null, tipo_pagamento = null): Observable<any> {
    let body = {
      condominioList: condominio_list,
      cod_rubrica: cod_rubrica,
      cod_fornecedor: cod_fornecedor,
      tipo_proc: tipo_proc,
      dt_desp: this.utils.getFormatedDate(dt_desp),
      dt_pag_valor: this.utils.getFormatedDate(dt_pag_valor),
      dt_pag: this.utils.getFormatedDate(dt_pag),
      dt_venc: this.utils.getFormatedDate(dt_venc),
      valor: valor,
      valor_pago: valor_pago,
      n_documento: n_documento,
      form_pagam: form_pagam,
      cod_conta_bancaria: cod_conta_bancaria,
      descricao: descricao,
      ref_interna: ref_interna,
      nid_movimento: nid_movimento,
      reparticao: reparticao,
      n_receita: '0',
      obj: obj,
      tipo_despesa: tipoDespesa,
      tipo_pagamento: tipo_pagamento,
    }
    return this.http.post(this.baseUrl + '/despesas/save', body, { headers: this.authHeaders });
  }

  getDespesasAgendamento(cod_condominio, start_date, end_date, estado: '-1' | '0' | '1', page: number | string = null, limit: number | string = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<{ success, data: Array<interfaces.agendamentoListApi> }> {
    page = (page) ? page : 'NULL';
    limit = (limit) ? limit : 'NULL';
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    estado = estado ? estado : '1';
    let now = this.utils.getFormatedDate(new Date());
    return this.http.get<{ success, data: Array<interfaces.agendamentoListApi> }>(this.baseUrl + '/despesas/getlistagendamento/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + estado + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection + '/' + now, { headers: this.authHeaders });
  }
  getDespesasAgendamentoByFornecedor(cod_fornecedor, start_date, end_date, estado: '-1' | '0' | '1', page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<{ success, data: Array<interfaces.agendamentoListApi> }> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    estado = estado ? estado : '1';
    let now = this.utils.getFormatedDate(new Date());
    return this.http.get<{ success, data: Array<interfaces.agendamentoListApi> }>(this.baseUrl + '/despesas/getlistagendamentobyfornecedor/' + cod_fornecedor + '/' + start_date + '/' + end_date + '/' + estado + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection + '/' + now, { headers: this.authHeaders });
  }
  delAgendamentos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/agendamentos/delete', body, { headers: this.authHeaders });
  }

  saveAndPayDespesaAgendamentoDetails(desp_list: Array<editAgendDate>, cod_condominio, n_agendamento): Observable<any> {
    let body = {
      list: desp_list,
      cod_condominio: cod_condominio,
      n_agendamento: n_agendamento
    }
    return this.http.post(this.baseUrl + '/despesas/saveagendamentodetails', body, { headers: this.authHeaders });
  }
  saveAgendamento(id, cod_condominio, n_agendamento, despesas: Array<interfaces.agendamentosSaveDespesaReq>, tipo_proc, reparticao, cod_rubrica, descricao, start_date, end_date, periodicidade, cod_fornecedor, metodo: 'L' | 'LP', reparticao_dist, datas_valor, datas_lancamento, obj, dt_estado: Date, estado: '0' | '1'): Observable<{ success, n_agendamento }> {
    let body: interfaces.agendamentosSaveReq = {
      id: id,
      cod_condominio: cod_condominio,
      n_agendamento: n_agendamento,
      tipo_proc: tipo_proc,
      reparticao: reparticao,
      cod_rubrica: cod_rubrica,
      descricao: descricao,
      start_date: start_date,
      end_date: end_date,
      periodicidade: periodicidade,
      despesas: despesas,
      cod_fornecedor: cod_fornecedor,
      metodo: metodo,
      reparticao_dist: reparticao_dist,
      datas_valor: datas_valor,
      datas_lancamento: datas_lancamento,
      obj: obj,
      dt_estado: dt_estado ? this.utils.getFormatedDate(dt_estado) : null,
      estado: estado,
    }
    return this.http.post<{ success, n_agendamento }>(this.baseUrl + '/agendamentos/save', body, { headers: this.authHeaders });
  }
  saveDespesa(despesaFilesPackage: Array<{ action, id, base64, ext, filename }>, linhas_despesa, id, cod, cod_condominio, n_despesa, cod_rubrica, cod_sub_rubrica, cod_fornecedor, tipo_proc, cod_proc, dtDespOriginal, dt_desp, dt_pag, dt_pag_valor, dt_venc, valor, valor_pago, n_documento, form_pagam, cod_conta_bancaria, descricao, n_doc_pagam, ref_interna, nid_movimento, reparticao, obj, valor_liquidado, estado_pagamento, descricaoCV, payment, is_ctt, tipo_despesa = null, tipo_pagamento = null, movimentoId = null, dtMovimento = null, id_caixa_vertis = null, id_registo_ctt = null, nome_condomino = null, cod_condomino = null, conta_simples = null, correio_normal = null, registado = null, aviso_recepcao = null, recibo_ctt = null, pag_ctt = null, n_envelope = null, n_folhas_env = null, custo_vertis = null, custo_ctt = null, valor_lancado = null, objCtt = null, condominos_obj = null, data = null, valorLimpeza): Observable<any> {
    let body = {
      id: id,
      despesaFilesPackage: despesaFilesPackage,
      cod_condominio: cod_condominio,
      n_despesa: n_despesa,
      cod_rubrica: cod_rubrica,
      cod_sub_rubrica: cod_sub_rubrica,
      cod_fornecedor: cod_fornecedor,
      tipo_proc: tipo_proc,
      cod_proc: cod_proc,
      dtDespOriginal: dtDespOriginal,
      dt_desp: this.utils.getFormatedDate(dt_desp),
      dt_pag: this.utils.getFormatedDate(dt_pag),
      dt_pag_valor: this.utils.getFormatedDate(dt_pag_valor),
      dt_venc: this.utils.getFormatedDate(dt_venc),
      valor: valor,
      valor_pago: valor_pago,
      n_documento: n_documento,
      form_pagam: form_pagam,
      cod_conta_bancaria: cod_conta_bancaria,
      descricao: descricao,
      n_doc_pagam: n_doc_pagam,
      ref_interna: ref_interna,
      nid_movimento: nid_movimento,
      reparticao: reparticao,
      n_receita: '0',
      obj: obj,
      valor_liquidado: valor_liquidado,
      estado_pagamento: estado_pagamento,
      tipo_despesa: tipo_despesa,
      tipo_pagamento: tipo_pagamento,
      linhas_despesa: linhas_despesa,
      movimento_id: movimentoId,
      dt_movimento: dtMovimento,
      dt_movimento_despesa: this.utils.getFormatedDate(dtMovimento),
      descricaoCV: descricaoCV,
      payment: payment,
      is_ctt: is_ctt,
      id_registo_ctt: id_registo_ctt,
      id_caixa_vertis: id_caixa_vertis,
      nome_condomino: nome_condomino,
      cod_condomino: cod_condomino,
      conta_simples: conta_simples,
      correio_normal: correio_normal,
      registado: registado,
      aviso_recepcao: aviso_recepcao,
      pag_ctt: pag_ctt,
      recibo_ctt: recibo_ctt,
      n_envelope: n_envelope,
      n_folhas_env: n_folhas_env,
      now: new Date(),
      custo_vertis: custo_vertis,
      custo_ctt: custo_ctt,
      valor_lancado: valor_lancado,
      objCtt: (objCtt) ? JSON.stringify(objCtt) : null,
      condominos_obj: (condominos_obj) ? JSON.stringify(condominos_obj) : null,
      data: data,
      is_from_today: dt_pag && this.utils.sameDateDay(dt_pag, new Date()) ? '1' : '0',
      was_from_today: dtDespOriginal && this.utils.sameDateDay(dtDespOriginal, new Date()) ? '1' : '0',
      valorLimpeza: valorLimpeza,
    }
    if (cod) body['cod'] = cod;
    return this.http.post(this.baseUrl + '/despesaREVIEW/save', body, { headers: this.authHeaders });
  }
  delDespesas(list, from = 'DESPESAS', delCriterio: 'DESPESA' | 'BOTH' = null): Observable<any> {
    let body = {
      list: list,
      from: from,
      delCriterio: delCriterio,
    }
    return this.http.post(this.baseUrl + '/despesas/delete', body, { headers: this.authHeaders });
  }
  updateDespesaMovimento(id, dt_valor, dt_mov, valor, nid_conta, descricao, id_despesa, n_despesa, cod_condominio, despObj, forma_pagamento, isCaixaVertisLoggedIn: boolean, descricaoCV = null, id_caixa_vertis = null): Observable<any> {
    let body = {
      id: id,
      dt_valor: (dt_valor) ? this.utils.getFormatedDate(dt_valor) : null,
      dt_mov: (dt_mov) ? this.utils.getFormatedDate(dt_mov) : null,
      valor: valor,
      nid_conta: nid_conta,
      descricao: descricao,
      id_despesa: id_despesa,
      n_despesa: n_despesa,
      cod_condominio: cod_condominio,
      desp_obj: (despObj) ? JSON.stringify(despObj) : null,
      forma_pagamento: forma_pagamento,
      now: new Date(),
      descricaoCV: descricaoCV,
      id_caixa_vertis: id_caixa_vertis,
      is_from_today: this.utils.sameDateDay(dt_mov, new Date()) ? '1' : '0',
      isCaixaVertisLoggedIn: isCaixaVertisLoggedIn,
    }
    return this.http.post(this.baseUrl + '/movimentos/update/despesas', body, { headers: this.authHeaders });
  }
  areDespesasCtt(despesas: Array<any>): Observable<any> {
    let body = {
      despesas: despesas
    }
    return this.http.post(this.baseUrl + '/despesas/arectt', body, { headers: this.authHeaders });
  }
  // --------------------- DESPESAS ENTITY - END


  // --------------------- RECIBOS ENTITY - START
  getRecibos(cod_condominio, start_date, end_date, filterByCreatedAt = 0, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    return this.http.get(this.baseUrl + '/recibos/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + filterByCreatedAt + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getRecibosV2(cod_condominio, start_date, end_date, filterByCreatedAt = 0, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    return this.http.get(this.baseUrl + '/recibos/getlistV2/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + filterByCreatedAt + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getRecibosDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/recibos/getdetails/' + id, { headers: this.authHeaders });
  }
  delRecibos(list, from = 'RECIBOS'): Observable<any> {
    let body = {
      list: list,
      from: from,
    }
    return this.http.post(this.baseUrl + '/recibos/delete', body, { headers: this.authHeaders });
  }
  getLinhasRecibos(recibos): Observable<any> {
    let body = {
      recibos: recibos,
    }
    return this.http.post(this.baseUrl + '/recibos/getlinhasrecibos', body, { headers: this.authHeaders });
  }
  canChangeDataRecibo(id_recibo: string): Observable<interfaces.defaultApiRequest> {
    return this.http.get<interfaces.defaultApiRequest>(this.baseUrl + '/recibos/canchangedata/' + id_recibo, { headers: this.authHeaders });
  }
  changeDataRecibo(id_recibo: string, newData: Date): Observable<interfaces.defaultApiRequest> {
    let body = {
      id_recibo: id_recibo,
      data: newData ? this.utils.getFormatedDate(newData) : null,
    }
    return this.http.post<interfaces.defaultApiRequest>(this.baseUrl + '/recibos/changedata', body, { headers: this.authHeaders });
  }
  // --------------------- RECIBOS ENTITY - END

  // --------------------- CREDITOS ENTITY - START
  getCreditos(cod_condominio, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    return this.http.get(this.baseUrl + '/creditos/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getCreditoDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/creditos/getdetails/' + id, { headers: this.authHeaders });
  }
  saveCredito(id, n_credito, cod_condominio, cod_fraccao, cod_pagador, credito, tipo_doc, n_doc, descricao, dt_valor, data, banco, conta_bancaria, valor, cod_conta_bancaria, prevValor = null, nome_condomino = null, prevCodContaBancaria = null): Observable<any> {
    let body = {
      id: id,
      n_credito: n_credito,
      cod_condominio: cod_condominio,
      cod_fraccao: cod_fraccao,
      cod_pagador: cod_pagador,
      credito: credito,
      tipo_doc: tipo_doc,
      n_doc: n_doc,
      descricao: descricao,
      dt_valor: this.utils.getFormatedDate(dt_valor),
      data: this.utils.getFormatedDate(data),
      banco: banco,
      conta_bancaria: conta_bancaria,
      valor: valor,
      cod_conta_bancaria: cod_conta_bancaria,
      prev_valor: prevValor,
      prev_cod_conta_bancaria: prevCodContaBancaria,
      nome_condomino: nome_condomino,
      now: new Date(),
    }
    return this.http.post(this.baseUrl + '/creditos/save', body, { headers: this.authHeaders });
  }
  delCreditos(list, from = 'CREDITOS'): Observable<any> {
    let body = {
      list: list,
      from: from,
    }
    return this.http.post(this.baseUrl + '/creditos/delete', body, { headers: this.authHeaders });
  }
  // --------------------- CREDITOS ENTITY - END

  // --------------------- RECEITAS ENTITY - START
  getReceitas(cod_condominio, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    cod_condominio = (cod_condominio) ? cod_condominio : 'NULL';
    return this.http.get(this.baseUrl + '/receitas/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getPagNaoIdentificados(codCondominio, endDate): Observable<any> {
    endDate = this.utils.getFormatedDate(endDate);
    return this.http.get(this.baseUrl + `/pagnaoidentificados/getlist/${codCondominio}/${endDate}`, { headers: this.authHeaders });
  }
  getReceitaDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/receitas/getdetails/' + id, { headers: this.authHeaders });
  }
  delReceitas(list, from = 'RECEITA'): Observable<any> {
    let body = {
      list: list,
      from: from,
    }
    return this.http.post(this.baseUrl + '/receitas/delete', body, { headers: this.authHeaders });
  }
  saveReceita(linhas_despesa, id, cod, cod_condominio, n_receita, cod_rubrica, cod_sub_rubrica, cod_fornecedor, tipo_proc, cod_proc, dt_desp, dt_valor, dt_pag, dt_venc, valor, valor_pago, n_documento, form_pagam, cod_conta_bancaria, descricao, n_doc_pagam, ref_interna, nid_movimento, reparticao, obj, tipo_pagamento, estado_pagamento, movimentoId, dtMovimento): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      n_despesa: '0',
      cod_rubrica: cod_rubrica,
      cod_sub_rubrica: cod_sub_rubrica,
      cod_fornecedor: cod_fornecedor,
      tipo_proc: tipo_proc,
      cod_proc: cod_proc,
      dt_desp: this.utils.getFormatedDate(dt_desp),
      dt_valor: this.utils.getFormatedDate(dt_valor),
      dt_pag: this.utils.getFormatedDate(dt_pag),
      dt_venc: this.utils.getFormatedDate(dt_venc),
      valor: valor,
      valor_pago: valor_pago,
      n_documento: n_documento,
      form_pagam: form_pagam,
      cod_conta_bancaria: cod_conta_bancaria,
      descricao: descricao,
      n_doc_pagam: n_doc_pagam,
      ref_interna: ref_interna,
      nid_movimento: nid_movimento,
      reparticao: reparticao,
      n_receita: n_receita,
      obj: obj,
      tipo_pagamento: tipo_pagamento,
      estado_pagamento: estado_pagamento,
      linhas_despesa: linhas_despesa,
      movimento_id: movimentoId,
      dt_movimento: dtMovimento,
      dt_movimento_receita: (dtMovimento) ? this.utils.getFormatedDate(dtMovimento) : null,
    }
    if (cod) body['cod'] = cod;
    return this.http.post(this.baseUrl + '/receita/save', body, { headers: this.authHeaders });
  }
  updateReceitasMovimento(id, dt_valor, dt_mov, valor, nid_conta, descricao, id_receita, n_receita, cod_condominio, despObj): Observable<any> {
    let body = {
      id: id,
      dt_valor: (dt_valor) ? this.utils.getFormatedDate(dt_valor) : null,
      dt_mov: (dt_mov) ? this.utils.getFormatedDate(dt_mov) : null,
      valor: valor,
      nid_conta: nid_conta,
      descricao: descricao,
      id_receita: id_receita,
      n_receita: n_receita,
      cod_condominio: cod_condominio,
      desp_obj: (despObj) ? JSON.stringify(despObj) : null,
    }
    return this.http.post(this.baseUrl + '/movimentos/update/receitas', body, { headers: this.authHeaders });
  }
  // --------------------- RECEITAS ENTITY - END

  // --------------------- ASSEMBLEIAS ENTITY - START
  getAssembleias(cod_condominio, start_date, end_date, page: number, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null, selStatus: string = null, selAssembleiaTipo: string = null, selUtilizador: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    limit = (limit) ? limit : -1;
    page = (page) ? page : -1;
    selStatus = (selStatus) ? selStatus : '0';

    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    if (!cod_condominio) cod_condominio = 'ALL';
    if (!selAssembleiaTipo || selAssembleiaTipo === 'TODOS') selAssembleiaTipo = 'ALL'
    if (!selUtilizador || selUtilizador === 'TODOS') selUtilizador = 'ALL'

    return this.http.get(this.baseUrl + '/assembleias/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection + '/' + selStatus + '/' + selAssembleiaTipo + '/' + selUtilizador, { headers: this.authHeaders });
  }
  getAssembleiasResults(cod_condominio, start_date, end_date, keyword: string = null, selStatus: string = null, selAssembleiaTipo: string = null, selUtilizador: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    selStatus = (selStatus) ? selStatus : '0';

    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    if (!cod_condominio) cod_condominio = 'ALL';
    if (!selAssembleiaTipo || selAssembleiaTipo === 'TODOS') selAssembleiaTipo = 'ALL'
    if (!selUtilizador || selUtilizador === 'TODOS') selUtilizador = 'ALL'

    return this.http.get(this.baseUrl + '/assembleias/getlistresults/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + keyword + '/' + selStatus + '/' + selAssembleiaTipo + '/' + selUtilizador, { headers: this.authHeaders });
  }
  getAssembleiasList(codCondominio, exercicio): Observable<any> {
    let endDate = `${exercicio}-12-31`;

    return this.http.get(this.baseUrl + `/assembleias/getlistforreport/${codCondominio}/${endDate}`, { headers: this.authHeaders });
  }
  getAtaAssinada(id, copy = false): Observable<any> {
    return this.http.get(this.baseUrl + '/assembleias/getata/' + id + '/' + copy, { headers: this.authHeaders });
  }
  delAssembleias(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/assembleias/delete', body, { headers: this.authHeaders });
  }
  getFile(id): Observable<{ success: boolean, data: { id, id_entidade, filename, file, ext, origem, userId, isPath, created_at, active }, status?}> {
    return this.http.get<{ success: boolean, data: { id, id_entidade, filename, file, ext, origem, userId, isPath, created_at, active }, status?}>(this.baseUrl + '/files/getfile/' + id, { headers: this.authHeaders });
  }
  getAnosSemContasAprovadas(cod_condominio: string, id_assembleia: number | string = null, data_convocatoria_dt: Date = null): Observable<{ success: boolean, data: Array<interfaces.AnoSemContasAprovadas> }> {
    id_assembleia = id_assembleia ? id_assembleia : 'NULL';
    let data_convocatoria: string = data_convocatoria_dt ? this.utils.getFormatedDate(data_convocatoria_dt) : 'NULL';
    return this.http.get<{ success: boolean, data: Array<interfaces.AnoSemContasAprovadas> }>(this.baseUrl + '/assembleias/getanossemcontasaprovadas/' + cod_condominio + '/' + id_assembleia + '/' + data_convocatoria, { headers: this.authHeaders });
  }
  getAssembleiaAvailableYears(cod_condominios: Array<string>): Observable<any> {
    let url = new URL(this.baseUrl + '/assembleias/available-years');
    cod_condominios.forEach(cod => {
      url.searchParams.append('cod[]', cod)
    })
    return this.http.get<any>(url.toString(), { headers: this.authHeaders });
  }
  getOrcamentosListAta(id_assembleia: number, exercicio): Observable<interfaces.orcamentoSelectionApi> {
    return this.http.get<interfaces.orcamentoSelectionApi>(this.baseUrl + '/assembleias/getorcamentos/' + id_assembleia + '/' + exercicio, { headers: this.authHeaders });
  }
  getAssembleiasParamFullInfo(params: Array<OrdemTrabalhoParametros>): Observable<GetAssembleiasParamsFullInfoAPI> {
    let url = new URL(this.baseUrl + '/assembleias/getparamsfullinfo');
    params.forEach(param => {
      url.searchParams.append('parametros[]', param.valor)
    })
    return this.http.get<GetAssembleiasParamsFullInfoAPI>(url.toString(), { headers: this.authHeaders });
  }
  entitiesCommunicationsAlreadySent(id_assembleia): Observable<EntitiesCommunicationAlreadySentAPI> {
    let url = new URL(this.baseUrl + '/assembleias/entitiescommunication/alreadysent');
    url.searchParams.append('id_assembleia', id_assembleia)
    return this.http.get<EntitiesCommunicationAlreadySentAPI>(url.toString(), { headers: this.authHeaders });
  }
  // --------------------- ASSEMBLEIAS ENTITY - END

  // --------------------- ASSEMBLEIAS AGENDAMENTOS ENTITY - START
  getAssembleiasAgendamentos(start_date, end_date): Observable<any> {
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/assembleias/getagendamentos/' + start_date + '/' + end_date, { headers: this.authHeaders });
  }
  getAssembleiaAgendamento(id): Observable<GetAgendamentoAssembleiaAPI> {
    return this.http.get<GetAgendamentoAssembleiaAPI>(this.baseUrl + '/assembleias/getagendamentodetails/' + id, { headers: this.authHeaders });
  }
  saveAssembleiasAgendamentos(saveAgendamento: SaveAgendamentoAssembleia): Observable<any> {
    let body = saveAgendamento;
    return this.http.post(this.baseUrl + '/assembleias/saveagendamentos', body, { headers: this.authHeaders });
  }
  delAssembleiaAgendamentos(list): Observable<any> {
    let body = {
      list: list,

      id_user: this.userSession.getUserId(),
      data: new Date(),
    }
    return this.http.post(this.baseUrl + '/assembleias/deleteagendamentos', body, { headers: this.authHeaders });
  }
  // --------------------- ASSEMBLEIAS AGENDAMENTOS ENTITY - END

  // --------------------- CONTAS CORRENTES - START
  getFraccoes(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/contascorrentes/getfraccoes/' + cod_condominio, { headers: this.authHeaders });
  }
  getCondominios(cod_condomino): Observable<any> {
    return this.http.get(this.baseUrl + '/contascorrentes/getcondominios/' + cod_condomino, { headers: this.authHeaders });
  }
  getContaCorrente(type: 'fraccoes', group, cod_condominio, fraccoesArr, startDate = null, endDate = null, tipoRelatorio = null, filter_recibo_date: Date = null): Observable<ContasCorrentesAPI> {
    let body = {
      fraccoes: fraccoesArr,
    }

    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);
    group = (group === '0' || group === 'fraccao') ? 'fraccao' : 'condomino';
    let filter_recibo_date_str = filter_recibo_date ? this.utils.getFormatedDate(filter_recibo_date) : null;

    let url = new URL(this.baseUrl + '/contascorrentes/' + type + '/' + group + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + tipoRelatorio);
    url.searchParams.set('filter_recibo_date', filter_recibo_date_str);
    return this.http.post<ContasCorrentesAPI>(url.toString(), body, { headers: this.authHeaders });
  }
  getContaCorrenteResumo(type, cod_condominio, fraccoesArr, startDate = null, endDate = null, tipoRelatorio = null): Observable<any> {
    let body = {
      fraccoes: fraccoesArr,
    }

    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);

    return this.http.post(this.baseUrl + '/contascorrentesresumo/' + type + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + tipoRelatorio, body, { headers: this.authHeaders });
  }
  getContaCorrenteCondomino(cod_condomino, condominiosArr, startDate = null, endDate = null, tipoRelatorio = null): Observable<any> {
    let body = {
      condominios: condominiosArr,
    }

    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);

    return this.http.post(this.baseUrl + '/contascorrentesbycondomino/' + cod_condomino + '/' + startDate + '/' + endDate + '/' + tipoRelatorio, body, { headers: this.authHeaders });
  }
  getContaCorrenteResumoCondomino(cod_condominio, condominiosArr, startDate = null, endDate = null, tipoRelatorio = null): Observable<any> {
    let body = {
      condominios: condominiosArr,
    }

    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);

    return this.http.post(this.baseUrl + '/contascorrentesresumobycondomino/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + tipoRelatorio, body, { headers: this.authHeaders });
  }
  getContaCorrenteFornecedor(cod_fornecedor, startDate = null, endDate = null, tipoDespesa = null, tipoRelatorio = null): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/contascorrentesbyfornecedor/' + cod_fornecedor + '/' + startDate + '/' + endDate + '/' + tipoDespesa + '/' + tipoRelatorio, { headers: this.authHeaders });
  }
  getContaCorrenteResumoFornecedor(cod_fornecedor, startDate = null, endDate = null, tipoDespesa = null, tipoRelatorio = null): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/contascorrentesresumobyfornecedor/' + cod_fornecedor + '/' + startDate + '/' + endDate + '/' + tipoDespesa + '/' + tipoRelatorio, { headers: this.authHeaders });
  }
  // --------------------- CONTAS CORRENTES - END

  // --------------------- RELATORIO EXERCICIO - START
  getRelExercListagemDespesas(cod_condominio, startDate = null, endDate = null, exercicio, emDivida = false): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/relatorioexercicio/despesas/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio, { headers: this.authHeaders });
  }

  getRelExercBalanceteV2(cod_condominio, subType, startDate = null, endDate = null, exercicio, emDivida = false): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/relatorioexercicioV2/balancete/' + subType + '/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio, { headers: this.authHeaders });
  }

  //Delete
  // getRelExercJustificacaoOrc(cod_condominio, startDate=null, endDate=null, exercicio, emDivida=false): Observable<any> {
  //   startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
  //   endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

  //   return this.http.get(this.baseUrl + '/relatorioexercicio/justificacao-orcamento/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio, { headers: this.authHeaders });
  // }

  getRelExercJustificacaoOrcV2(cod_condominio, startDate = null, endDate = null, exercicio, emDivida = false): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/relatorioexercicioV2/justificacao-orcamento/NULL/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio, { headers: this.authHeaders });
  }

  getRelExercMapaQuotas(cod_condominio, data_limite_pagamentos: Date, startDate = null, endDate = null, exercicio, emDivida = false): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';
    let data_limite_pagamentos_p = (data_limite_pagamentos) ? this.utils.getFormatedDate(data_limite_pagamentos) : null;

    let url = new URL(this.baseUrl + '/relatorioexercicio/mapa-quotas/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio);
    url.searchParams.set('data_limite_pagamentos', data_limite_pagamentos_p);

    return this.http.get(url.toString(), { headers: this.authHeaders });
  }

  getRelExercResumoFinanceiro(cod_condominio, startDate = null, endDate = null, exercicio, emDivida = false): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';

    return this.http.get(this.baseUrl + '/relatorioexercicio/resumo-financeiro/' + emDivida + '/' + cod_condominio + '/' + startDate + '/' + endDate + '/' + exercicio, { headers: this.authHeaders });
  }

  getRelExercCaixas(endDate = null, isOptimized = true): Observable<any> {
    endDate = (!isOptimized && endDate) ? formatDate(endDate, this.format, this.locale) : null;
    return this.http.get(this.baseUrl + '/relatorioexercicio/caixas/null/null/null/' + endDate + '/null', { headers: this.authHeaders });
  }

  getRelReconcBancaria(endDate): Observable<interfaces.getRelatorioReconciliacaoBancaria> {
    endDate = formatDate(endDate, this.format, this.locale);
    return this.http.get<interfaces.getRelatorioReconciliacaoBancaria>(this.baseUrl + '/report/bankreconciliation/' + endDate, { headers: this.authHeaders });
  }

  getRecibosAnulados(endDate): Observable<any> {
    endDate = formatDate(endDate, this.format, this.locale);
    return this.http.get(this.baseUrl + '/report/deletedreceipts/' + endDate, { headers: this.authHeaders });
  }

  getListagemRecibos(startDate, endDate, userId): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';
    return this.http.get(this.baseUrl + '/report/getreceiptsbyuser/' + startDate + "/" + endDate + "/" + userId, { headers: this.authHeaders });
  }

  getListagemDespesas(startDate, endDate, userId): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : 'NULL';
    return this.http.get(this.baseUrl + '/report/getdespesasbyuser/' + startDate + "/" + endDate + "/" + userId, { headers: this.authHeaders });
  }
  // --------------------- RELATORIO EXERCICIO - END

  // --------------------- REGISTO CTT - START
  getRegistoCtt(start_date, end_date, keyword): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/registoctt/getlist/' + start_date + '/' + end_date + '/' + keyword, { headers: this.authHeaders });
  }
  getRegistoCttDetails(id_despesa): Observable<any> {
    return this.http.get(this.baseUrl + '/registoctt/getdetails/' + id_despesa, { headers: this.authHeaders });
  }
  saveRegistoCtt(id = null, cod_condominio = null, id_despesa = null, n_despesa = null, descricao = null, nome_condomino = null, cod_condomino = null, conta_simples = null, correiro_normal = null, registado = null, aviso_recepcao = null, data_despesa = null, id_user = null, recibo_ctt = null, pag_ctt = null, n_envelope = null, n_folhas_env = null, custo_vertis = null, custo_ctt = null, valor_lancado = null, obj = null, condominos_obj = null, data = null, id_mov_caixa_vertis = null): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      cod_condomino: cod_condomino,
      nome_condomino: nome_condomino,
      id_despesa: id_despesa,
      n_despesa: n_despesa,
      descricao: (descricao) ? descricao : '',
      conta_simples: conta_simples,
      correiro_normal: correiro_normal,
      registado: registado,
      aviso_recepcao: aviso_recepcao,
      data_despesa: data_despesa,
      id_user: id_user,
      recibo_ctt: recibo_ctt,
      pag_ctt: pag_ctt,
      n_envelope: n_envelope,
      n_folhas_env: n_folhas_env,
      custo_vertis: custo_vertis,
      custo_ctt: custo_ctt,
      valor_lancado: valor_lancado,
      obj: (obj) ? JSON.stringify(obj) : null,
      condominos_obj: (condominos_obj) ? JSON.stringify(condominos_obj) : null,
      data: data,
      id_mov_caixa_vertis: id_mov_caixa_vertis,
    }
    return this.http.post(this.baseUrl + '/registoctt/save', body, { headers: this.authHeaders });
  }
  saveRegistoCTTDetailed(registosCTT: Array<RegistoCTTSaveDetailed>): Observable<any> {
    let body = registosCTT
    return this.http.post(this.baseUrl + '/registoctt/savedetailed', body, { headers: this.authHeaders });
  }
  delRegistoCtt(list, from: 'REGISTO_COMUNICACOES' | 'CUSTOS_COMUNICACOES', delCriterio: 'DESPESA' | 'BOTH'): Observable<any> {
    let body = {
      list: list,
      delCriterio: delCriterio,
      from: from,
      nowDate: new Date(),
    }
    return this.http.post(this.baseUrl + '/registoctt/delete', body, { headers: this.authHeaders });
  }
  saveCartaPDF(id_registo_ctt: Array<any>, base64, origem, filename): Observable<any> {
    let body = {
      ids_registo_ctt: id_registo_ctt,
      base64: base64,
      origem: origem,
      ext: '.pdf',
      filename: filename
    }
    return this.http.post(this.baseUrl + '/registoctt/savePDF', body, { headers: this.authHeaders });
  }
  // --------------------- REGISTO CTT - END

  // --------------------- REGISTO PROCESSOS - START
  getRegistoProcessos(start_date = null, end_date = null, keyword = null): Observable<any> {
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/registoaprocessos/getlist/' + start_date + '/' + end_date + '/' + keyword, { headers: this.authHeaders });
  }
  launchProcesso(nome_processo, label_processo, data_inicio = new Date()): Observable<any> {
    let body = {
      nome_processo: nome_processo,
      label_processo: label_processo,
      data_inicio: data_inicio,
      id_user: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/processos/launch', body, { headers: this.authHeaders });
  }
  getProcesso(id): Observable<any> {
    return this.http.get(this.baseUrl + '/registoaprocessos/get/' + id, { headers: this.authHeaders });
  }
  delRegistoProcessos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/registoaprocessos/delete', body, { headers: this.authHeaders });
  }
  setDespAgendamentoMaintenance(): Observable<any> {
    return this.http.get(this.baseUrl + '/registoaprocessos/maintenance/agendamentosschedulepayments', { headers: this.authHeaders });
  }
  // --------------------- REGISTO PROCESSOS - END

  // --------------------- REGISTO ACTIVIDADE - START
  getRegistoActividade(start_date = null, end_date = null, keyword = null, id_user = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    id_user = (id_user) ? id_user : 'NULL';
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/registoactividade/getlist/' + start_date + '/' + end_date + '/' + keyword + '/' + id_user, { headers: this.authHeaders });
  }
  saveRegistoAtividade(cod_condominio = null, tipo_doc = null, n_doc = null, titulo = null, descricao = null, obj = null, obs = null): Observable<any> {
    let body = {
      id_user: this.userSession.getUserId(),
      tipo_doc: tipo_doc,
      n_doc: n_doc,
      titulo: titulo,
      obs: obs,
      cod_condominio: cod_condominio,
      descricao: (descricao) ? descricao : '',
      obj: (obj) ? JSON.stringify(obj) : null,
      data: new Date(),
    }
    return this.http.post(this.baseUrl + '/registoactividade/save', body, { headers: this.authHeaders });
  }
  saveRegistoAtividadeNew(reg: RegistoAtividadeSave): Observable<any> {
    let body = reg;
    return this.http.post(this.baseUrl + '/registoatividade/save', body, { headers: this.authHeaders });
  }
  saveRegistoActividade(cod_condominio = null, tipo_doc = null, n_doc = null, titulo = null, descricao = null, obj = null, obs = null): Observable<any> {
    let body = {
      id_user: this.userSession.getUserId(),
      tipo_doc: tipo_doc,
      n_doc: n_doc,
      titulo: titulo,
      obs: obs,
      cod_condominio: cod_condominio,
      descricao: (descricao) ? descricao : '',
      obj: (obj) ? JSON.stringify(obj) : null,
      data: new Date(),
    }
    return this.http.post(this.baseUrl + '/registoactividade/save', body, { headers: this.authHeaders });
  }
  delRegistoActividade(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/registoactividade/delete', body, { headers: this.authHeaders });
  }
  getRegAtividadeActions(type): Observable<interfaces.RegAtividadeActionsArrayApi> {
    return this.http.get<interfaces.RegAtividadeActionsArrayApi>(this.baseUrl + '/registoactividade/getactions/' + type, { headers: this.authHeaders });
  }
  // --------------------- REGISTO ACTIVIDADE - END

  // --------------------- CAIXA VERTIS - START
  getCaixaVertisReport(start_date, end_date, keyword): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/caixavertis/getlist/' + start_date + '/' + end_date + '/' + keyword, { headers: this.authHeaders });
  }
  isCaixaVertisLoggedIn(): Observable<any> {
    let id_user = this.userSession.getUserId();
    return this.http.get(this.baseUrl + '/caixavertis/isloggedin/' + id_user, { headers: this.authHeaders });
  }
  acceptCaixaVertisSession(): Observable<any> {
    let id_user = this.userSession.getUserId();
    return this.http.get(this.baseUrl + '/caixavertis/acceptsession/' + id_user, { headers: this.authHeaders });
  }
  denyCaixaVertisSession(): Observable<any> {
    let id_user = this.userSession.getUserId();
    return this.http.get(this.baseUrl + '/caixavertis/denysession/' + id_user, { headers: this.authHeaders });
  }
  hasCaixaVertisOpened(): Observable<any> {
    let now = this.utils.getFormatedDate(new Date(), 'yyyy-MM-dd');

    let body = {
      now: now,
    }
    return this.http.post(this.baseUrl + '/caixavertis/isopened', body, { headers: this.authHeaders });
  }
  hasCaixaVertisMovimento(cod_condominio, tipo, n_doc): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
      tipo: tipo,
      n_doc: n_doc,
    }
    return this.http.post(this.baseUrl + '/caixavertis/hasmovimento', body, { headers: this.authHeaders });
  }
  openCaixaVertis(): Observable<any> {
    let body = {
      dt: new Date(),
      id_user: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/caixavertis/open', body, { headers: this.authHeaders });
  }
  closeCaixaVertis(id_last_entry, n_caixa, saldo): Observable<any> {
    let body = {
      id: id_last_entry,
      dt_fecho: new Date(),
      n_caixa: n_caixa,
      saldo: saldo,
      id_user: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/caixavertis/close', body, { headers: this.authHeaders });
  }
  addMovimentoCaixaVertis(dt_abertura, n_caixa, dt_mov, cod_condominio, descricao, tipo, valor, idFuncionario, idUtilizador, obj = null, nomeDeclaracao = null, assunto = null, cttObj = null): Observable<any> {
    let body = {
      dt_abertura: dt_abertura,
      n_caixa: n_caixa,
      cod_condominio: cod_condominio,
      descricao: descricao,
      tipo: tipo,
      valor: valor,
      dt_mov: dt_mov,
      id_user: this.userSession.getUserId(),
      today: new Date(),
      obj: (obj) ? JSON.stringify(obj) : null,
      id_funcionario: idFuncionario,
      id_utilizador: idUtilizador,
      nome_declaracao: nomeDeclaracao,
      assunto: assunto,
      cttObj: cttObj,
    }
    return this.http.post(this.baseUrl + '/caixavertis/addmovimento', body, { headers: this.authHeaders });
  }
  updateMovimentoCaixaVertis(id, cod_condominio, descricao, tipo, valor, idFuncionario, idUtilizador, obj = null, obj_reg_ctt = null): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      descricao: descricao,
      tipo: tipo,
      valor: valor,
      id_funcionario: idFuncionario,
      id_utilizador: idUtilizador,
      id_user: this.userSession.getUserId(),
      obj: (obj) ? JSON.stringify(obj) : null,
      obj_reg_ctt: (obj_reg_ctt) ? JSON.stringify(obj_reg_ctt) : null,
    }
    return this.http.post(this.baseUrl + '/caixavertis/editmovimento', body, { headers: this.authHeaders });
  }
  delCaixaVertisEntry(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/caixavertis/deleteentry', body, { headers: this.authHeaders });
  }
  getLastEntregaCtt(): Observable<any> {
    return this.http.get(this.baseUrl + '/caixavertis/getlastentregactt', { headers: this.authHeaders });
  }
  // --------------------- CAIXA VERTIS - END
  // --------------------- EMAIL SERVICES - END
  getMapaReuniao(cod_condominio, start_date, end_date, page: number = null, limit: number = null, keyword: string = null, sortParam: string = null, sortDirection: string = null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';

    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/mapareuniao/getlist/' + cod_condominio + '/' + start_date + '/' + end_date + '/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  getMapaReuniaoDetails(id_assembleia): Observable<any> {
    return this.http.get(this.baseUrl + '/mapareuniao/getdetails/' + id_assembleia, { headers: this.authHeaders });
  }
  getMapaReuniaoByUser(date): Observable<any> {
    let id_utilizador = this.userSession.getUserId();
    date = this.utils.getFormatedDate(date);

    return this.http.get(this.baseUrl + '/mapareuniao/getlistbyuser/' + id_utilizador + '/' + date, { headers: this.authHeaders });
  }
  saveMapaReuniao(id, data_reuniao, utilizador_id, data_convocatoria, data_limite, data_ata, entrega_assinatura, rececao, copia_ata, arquivo_digital, obs, processo_concluido, id_assembleia, reuniao_realizada = null): Observable<any> {
    let dtAux = new Date(data_reuniao.getTime() + 15 * 60000);  // ADD 15MIN FOR THE SECUND TRY!!

    let body = {
      id: id,
      data_reuniao: data_reuniao,
      utilizador_id: utilizador_id,
      data_convocatoria: data_convocatoria,
      data_limite: data_limite,
      data_ata: data_ata,
      entrega_assinatura: entrega_assinatura,
      rececao: rececao,
      copia_ata: copia_ata,
      arquivo_digital: arquivo_digital,
      obs: obs,
      processo_concluido: (processo_concluido) ? '1' : '0',

      id_assembleia: id_assembleia,
      dt: (data_reuniao) ? this.utils.getFormatedDate(data_reuniao) : null,
      dt_alter: (dtAux) ? this.utils.getFormatedDate(dtAux) : null,
      hora: (data_reuniao) ? formatDate(data_reuniao, 'HH:mm', this.locale) : null,
      hora_alter: (dtAux) ? formatDate(dtAux, 'HH:mm', this.locale) : null,

      reuniao_realizada: null,
    }
    if (reuniao_realizada !== null) {
      body.reuniao_realizada = (reuniao_realizada) ? '1' : '0';
    }
    return this.http.post(this.baseUrl + '/mapareuniao/save', body, { headers: this.authHeaders });
  }
  mapaReuniaoUpdateDatas(id, target, date): Observable<any> {
    // target: DATA_ENTREGA_ASSINATURA, DATA_COPIA_ATA
    let body = {
      id: id,
      target: target,
      date: date,
    }
    return this.http.post(this.baseUrl + '/mapareuniao/updatedate', body, { headers: this.authHeaders });
  }
  delMapaReuniao(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/mapareuniao/delete', body, { headers: this.authHeaders });
  }

  // --------------------- EMAIL CONFIG ENTITY - START
  getEmailConfigs(): Observable<any> {
    return this.http.get(this.baseUrl + '/emailconfig/getlist', { headers: this.authHeaders });
  }
  updateEmailConfigs(id, legenda, emailSubject, emailBody): Observable<any> {
    let body = {
      id: id,
      legenda: legenda,
      subject: emailSubject,
      body: emailBody,
    }
    return this.http.post(this.baseUrl + '/emailconfig/update', body, { headers: this.authHeaders });
  }
  getEmailConfig(idEmail): Observable<any> {
    return this.http.get(`${this.baseUrl}/emailconfig/getdetails/${idEmail}`, { headers: this.authHeaders });
  }
  getEmailTemplate(id, idOrdemTrabalho): Observable<any> {
    return this.http.get(`${this.baseUrl}/emailconfig/getdetails/${id}/${idOrdemTrabalho}`, { headers: this.authHeaders });
  }
  getEmailTemplateByType(type): Observable<interfaces.getEmailTemplateApi> {
    return this.http.get<interfaces.getEmailTemplateApi>(`${this.baseUrl}/emailconfig/getdetailsbytype/${type}`, { headers: this.authHeaders });
  }
  // --------------------- EMAIL CONFIG ENTITY - END

  // --------------------- TIPO ANEXOS ENTITY - START
  getTiposAnexos(type = 'ANEXO_ATA'): Observable<any> {
    return this.http.get(this.baseUrl + '/tiposanexo/getlist/' + type, { headers: this.authHeaders });
  }
  delTiposAnexos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/tiposanexo/delete', body, { headers: this.authHeaders });
  }
  addTiposAnexos(name, ordem): Observable<any> {
    let body = {
      name: name,
      ordem: ordem,
    }
    return this.http.post(this.baseUrl + '/tiposanexo/add', body, { headers: this.authHeaders });
  }
  updateTiposAnexos(id, name, ordem): Observable<any> {
    let body = {
      id: id,
      name: name,
      ordem: ordem,
    }
    return this.http.post(this.baseUrl + '/tiposanexo/update', body, { headers: this.authHeaders });
  }
  // --------------------- TIPO ANEXOS ENTITY - END

  getEmailOpts(): Observable<any> {
    return this.http.get(this.baseUrl + '/config/getemaillist', { headers: this.authHeaders });
  }
  getCartaOpts(): Observable<any> {
    return this.http.get(this.baseUrl + '/config/getcartalist', { headers: this.authHeaders });
  }

  // --------------------- REGISTO COMUNICACAO - START
  getEmail(id): Observable<{ success, data: EmailSent }> {
    return this.http.get<{ success, data: EmailSent }>(this.baseUrl + '/registocomunicaco/getemail/' + id, { headers: this.authHeaders });
  }
  getEmailData(id): Observable<{ success, data: EmailSent }> {
    return this.http.get<{ success, data: EmailSent }>(this.baseUrl + '/registocomunicaco/getemaildata/' + id, { headers: this.authHeaders });
  }
  getEmailAttachments(id): Observable<{ success, data: Array<Anexo> }> {
    return this.http.get<{ success, data: Array<Anexo> }>(this.baseUrl + '/registocomunicaco/getemailattachments/' + id, { headers: this.authHeaders });
  }
  getRegistoComunicacoes(metodoComunicacao, tipoCorreio, keyword = null, page = 0, limit = 20, startDate = null, endDate = null, entityFilter: { cod_entidade: string, tipo_entidade: TipoEntidade } = null): Observable<{ success, data: { reg_com: Array<any> } }> {

    keyword = keyword ? keyword : 'NULL'
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);

    let url = new URL(this.baseUrl + '/registocomunicacao/getlist');
    url.searchParams.set('metodoComunicacao', metodoComunicacao);
    url.searchParams.set('tipoCorreio', tipoCorreio);
    url.searchParams.set('page', page.toString());
    url.searchParams.set('limit', limit.toString());
    url.searchParams.set('keyword', keyword);
    url.searchParams.set('startDate', startDate);
    url.searchParams.set('endDate', endDate);
    if (entityFilter) {
      Object.keys(entityFilter).forEach(key => {
        url.searchParams.set(key, entityFilter[key]);
      });
    }

    return this.http.get<{ success, data: { reg_com: Array<any> } }>(url.toString(), { headers: this.authHeaders });
  }
  saveRegistoComunicacaoCorrespondencia(origem, id_texto_predefinido, data_dados, assunto, corpo, regList: Array<RegistoComunicacaoCorrespondenciaApi>, id_utilizador, data, msg = null, obj = null): Observable<any> {
    let body = {
      origem: origem,
      id_entidade: null,
      id_texto_predefinido: id_texto_predefinido,
      data_dados: data_dados,
      assunto: assunto,
      corpo: corpo,
      regList: regList,
      id_utilizador: id_utilizador,
      data: data,
      msg: msg,
      obj: (obj) ? JSON.stringify(obj) : null,
    }
    return this.http.post(this.baseUrl + '/registocomunicacao/savelist', body, { headers: this.authHeaders });
  }
  saveRegistoComunicacao(body: Array<RegistoComunicacaoSave>): Observable<any> {
    return this.http.post(this.baseUrl + '/registocomunicacao/save', body, { headers: this.authHeaders });
  }
  delRegistoComunicacao(list): Observable<any> {
    let body = {
      list: list,
      nowDate: new Date(),
    }
    return this.http.post(this.baseUrl + '/registocomunicacao/delete', body, { headers: this.authHeaders });
  }
  // --------------------- REGISTO COMUNICACAO - END
  // --------------------- CUSTOS COMUNICACAO - START
  getCustosComunicacoes(keyword = null, page = 0, limit = 20, startDate = null, endDate = null): Observable<{ success, data: { custos: Array<custosComunicacoesListCorrespondencia>, totals: { total, totalCustoCtt, totalCustoVertis } } }> {
    keyword = (keyword) ? keyword : 'NULL';
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);
    return this.http.get<{ success, data: { custos: Array<custosComunicacoesListCorrespondencia>, totals: { total, totalCustoCtt, totalCustoVertis } } }>(this.baseUrl + '/custoscomunicacoes/getlist/' + page + '/' + limit + '/' + keyword + '/' + startDate + '/' + endDate, { headers: this.authHeaders });
  }
  getCurrentsCustosComunicacoes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/custoscomunicacoes/getcurrents', { headers: this.authHeaders });
  }
  getNewEntregaCustosComunicacoes(): Observable<{ success, data: { custo_vertis, custo_ctt, valor } }> {
    return this.http.get<{ success, data: { custo_vertis, custo_ctt, valor } }>(this.baseUrl + '/custoscomunicacoes/getnewentregavalues', { headers: this.authHeaders });
  }
  getEntregaByFatura(id): Observable<{ success, data: custosComunicacoes }> {
    return this.http.get<{ success, data: custosComunicacoes }>(this.baseUrl + '/custoscomunicacoes/getentregabyfatura/' + id, { headers: this.authHeaders });
  }
  getFaturaByEntrega(table: 'CAIXA_VERTIS', id_cv): Observable<{ success, data: custosComunicacoes }> {
    return this.http.get<{ success, data: custosComunicacoes }>(this.baseUrl + '/custoscomunicacoes/getfaturabyentrega/' + table + '/' + id_cv, { headers: this.authHeaders });
  }
  getCustoComunicacaoByCV(id_cv): Observable<{ success, data: custosComunicacoes }> {
    return this.http.get<{ success, data: custosComunicacoes }>(this.baseUrl + '/custoscomunicacoes/getbycaixavertis/' + id_cv, { headers: this.authHeaders });
  }
  saveCustosComunicacao(fatura: custosComunicacoes): Observable<interfaces.defaultApiRequest> {
    return this.http.post<interfaces.defaultApiRequest>(this.baseUrl + '/custoscomunicacoes/save', fatura, { headers: this.authHeaders });
  }
  delCustosComunicacoes(list): Observable<interfaces.defaultApiRequest> {
    let body = {
      list: list
    }
    return this.http.post<interfaces.defaultApiRequest>(this.baseUrl + '/custoscomunicacoes/delete', body, { headers: this.authHeaders });
  }
  getEntregasFaturas(keyword = null, page = 0, limit = 20, startDate = null, endDate = null): Observable<{ success, data: Array<custosComunicacoes> }> {
    keyword = (keyword) ? keyword : 'NULL';
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);
    return this.http.get<{ success, data: Array<custosComunicacoes> }>(this.baseUrl + '/entregasfaturas/getlist/' + page + '/' + limit + '/' + keyword + '/' + startDate + '/' + endDate, { headers: this.authHeaders });
  }
  delEntregasFaturas(list): Observable<{ success }> {
    let body = {
      list: list
    }
    return this.http.post<{ success }>(this.baseUrl + '/entregasfaturas/delete', body, { headers: this.authHeaders });
  }
  // --------------------- CUSTOS COMUNICACAO - END
  // --------------------- EMAIL REGISTRATION - START
  getEmailLogs(origem, id_assembleia): Observable<any> {
    return this.http.get(this.baseUrl + '/registoactividade/getemaillist/' + origem + '/' + id_assembleia, { headers: this.authHeaders });
  }
  saveRegistoComunicacaoAssembleias(origem, id_entidade, cod_condominio, descricao, email_list, id_utilizador, data, msg = null, obj = null): Observable<any> {
    let body = {
      origem: origem,
      id_entidade: id_entidade,
      cod_condominio: cod_condominio,
      descricao: descricao,
      email_list: email_list,
      id_utilizador: id_utilizador,
      data: data,
      msg: msg,
      obj: (obj) ? JSON.stringify(obj) : null,
    }
    return this.http.post(this.baseUrl + '/registoactividade/saveemaillist', body, { headers: this.authHeaders });
  }
  delEmailLogs(id): Observable<any> {
    return this.http.get(this.baseUrl + '/registoactividade/deleteemaillist' + id, { headers: this.authHeaders });
  }
  // --------------------- EMAIL SERVICES - START
  //TODO Usar sempre este serviço e alterar o script para receber um body->email e não body->email_list
  //Era usado o array para enviar vários condóminos em TO, mas pode-se usar o bcc agora
  sendEmail(email: Email, permission_field = null): Observable<any> {
    if (this.devMode) {
      email.to = email.to.length ? [{ name: 'DEV TO', email: this.devEmailAddr, fraccao: email.to[0].fraccao }] : [];
      email.bcc = email.bcc.length ? [{ name: 'DEV BCC', email: this.devEmailAddr, fraccao: email.bcc[0].fraccao }] : [];
      email.cc = email.cc.length ? [{ name: 'DEV CC', email: this.devEmailAddr, fraccao: email.cc[0].fraccao }] : [];
    }
    email.date = new Date();
    let body = {
      email_list: [email],
      permission_field: permission_field,
      userId: this.userSession.getUserId(),
    }
    return this.http.post(this.emailBaseUrl, body, { headers: this.authHeaders });
  }

  // id_comunicacao is for assembleias or for saving attachments in correspondencia
  sendEmailV2(emailList, id_comunicacao = null, permission_field = null): Observable<any> {
    if (this.devMode) {
      emailList.forEach(el => {
        el.to = this.devEmailAddr;
        el['cc'] = null;
        el['bcc'] = null;
      });
    }
    emailList.forEach(el => { el.id_comunicacao = id_comunicacao });
    let body = {
      email_list: emailList,
      permission_field: permission_field,
      userId: this.userSession.getUserId(),
    }
    return this.http.post(this.emailBaseUrl, body, { headers: this.authHeaders });
  }
  sendEmailV2Arr(emailList): Observable<any> {
    if (this.devMode) {
      emailList.forEach(el => {
        el.to = el.to.map(el => {
          return this.devEmailAddr;
        });
      });
    }

    let body = {
      email_list: emailList,
    }
    return this.http.post(this.emailBaseUrl, body, { headers: this.authHeaders });
  }
  getAdminEmailList(): Observable<any> {
    return this.http.get(this.baseUrl + '/utilizadores/getadminemaillist', { headers: this.authHeaders });
  }
  // --------------------- EMAIL SERVICES - END
  // --------------------- EMAIL REGISTRATION - END


  // START - PERMISSSIONS V2 ----------------------------------------------------------------------
  getPermissionsStructure(): Observable<any> {
    return this.http.get(this.baseUrl + '/permissions/structure', { headers: this.authHeaders });
  }
  getPermissionProfiles(): Observable<any> {
    return this.http.get(this.baseUrl + '/permissions/profiles', { headers: this.authHeaders });
  }
  getUserPermissions(userId): Observable<any> {
    return this.http.get(this.baseUrl + '/permissions/userpermissions/' + userId, { headers: this.authHeaders });
  }

  savePermissions(userId, permissions, customRules, isAdmin, permissionProfile): Observable<any> {
    let body = {
      idUser: userId,
      permissions: permissions,
      customRules: customRules,
      isSuperAdmin: (isAdmin) ? '1' : '0',
      permissionProfile: permissionProfile,
    }
    return this.http.post(this.baseUrl + '/permissions/savepermissions', body, { headers: this.authHeaders });
  }
  // END - PERMISSSIONS V2 ------------------------------------------------------------------------


  // SERVER SIDE PDF METHODS 
  mergePdf(fileName, contentType, base64Arr): Observable<any> {
    let body = {
      fileName: fileName,
      contentType: contentType,
      base64Arr: base64Arr.map(el => {
        el = el.replace(contentType, '');
        return el;
      }),
    }
    return this.http.post(this.mergePdfBaseUrl, body, { headers: this.authHeaders });
  }

  // CHECKUP ROUTINE ----------------------------------------------------------
  getAvisosSemRecibos(codCondominio): Observable<any> {
    return this.http.get(this.baseUrl + '/checkup/checkavisospagossemrecibos/' + codCondominio, { headers: this.authHeaders });
  }

  getFraccoesORC(codOrcamento): Observable<any> {
    return this.http.get(this.baseUrl + '/checkup/getfraccoesorc/' + codOrcamento, { headers: this.authHeaders });
  }
  getFraccoesFCR(codOrcamento): Observable<any> {
    return this.http.get(this.baseUrl + '/checkup/getfraccoesfcr/' + codOrcamento, { headers: this.authHeaders });
  }
  fixOrcamento(toUpdate): Observable<any> {
    return this.http.post(this.baseUrl + '/checkup/fixorcamento', { toUpdate: toUpdate }, { headers: this.authHeaders });
  }

  getTitularesBancarios(codOrcamento): Observable<any> {
    return this.http.get(this.baseUrl + '/checkup/gettitularesbancarios/' + codOrcamento, { headers: this.authHeaders });
  }


  // MAIL LETTER COMUNICATION -------------------------------------------------
  getCondominioContactDetails(codCondominio): Observable<any> {
    return this.http.get(this.baseUrl + `/condominiums/getcontactdetails/${codCondominio}`, { headers: this.authHeaders });
  }
  getEntityContactDetails(codEntity, entityType = 'PROPRIETARIO'): Observable<GetEntitiesContactDetails> {
    return this.http.get<GetEntitiesContactDetails>(this.baseUrl + `/entities/getcontactdetails/${entityType}/${codEntity}`, { headers: this.authHeaders });
  }


  // PROPOSTAS ----------------------------------------------------------------
  getPropostas(keyword = null, page = 0, limit = 20, startDate = null, endDate = null): Observable<any> {
    startDate = (startDate) ? formatDate(startDate, this.format, this.locale) : 'NULL';
    endDate = (endDate) ? formatDate(endDate, this.format, this.locale) : formatDate(new Date(), this.format, this.locale);

    return this.http.get(this.baseUrl + '/proposal/getlist/' + page + '/' + limit + '/' + keyword + '/' + startDate + '/' + endDate, { headers: this.authHeaders });
  }
  saveProposta(body): Observable<any> {
    return this.http.post(this.baseUrl + '/proposal/update', body, { headers: this.authHeaders });
  }
  deletePropostas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/proposal/delete', body, { headers: this.authHeaders });
  }

  // MINUTAS
  getAllMinutas(): Observable<{ success, data: Array<Minuta> }> {
    return this.http.get<{ success, data: Array<Minuta> }>(this.baseUrl + '/minutas/getall', { headers: this.authHeaders });
  }
  addMinuta(minuta: NewMinuta): Observable<{ success, data: Minuta, status?}> {
    return this.http.post<{ success, data: Minuta, status?}>(this.baseUrl + '/minutas/add', minuta, { headers: this.authHeaders });
  }
  updateMinuta(minuta: Minuta): Observable<interfaces.defaultApiRequest> {
    return this.http.post<interfaces.defaultApiRequest>(this.baseUrl + '/minutas/save', minuta, { headers: this.authHeaders });
  }
  deleteMinutas(list: Array<Minuta>): Observable<any> {
    return this.http.post(this.baseUrl + '/minutas/delete', list, { headers: this.authHeaders });
  }

  // Avisos e Cobrança
  getAvisosCobrancaHypotheses(): Observable<getAvisosCobrancaHypothesesAPI> {
    return this.http.get<getAvisosCobrancaHypothesesAPI>(this.baseUrl + '/configurations/avisoscobranca/gethypotheses', { headers: this.authHeaders });
  }
  getConfigAvisosCobrancaHypothesisDetails(id_hypo): Observable<getAvisosCobrancaHypothesisDetailsAPI> {
    let url = new URL(this.baseUrl + '/configurations/avisoscobranca/gethypothesisdetails');
    url.searchParams.set('hypothesis', id_hypo ? id_hypo.toString() : null);
    return this.http.get<getAvisosCobrancaHypothesisDetailsAPI>(url.toString(), { headers: this.authHeaders });
  }
  saveAvisosCobrancaHypothesisDetails(body: savehypothesisBodyAPI): Observable<any> {
    let url = new URL(this.baseUrl + '/configurations/avisoscobranca/savehypothesis');
    return this.http.post<any>(url.toString(), body, { headers: this.authHeaders });
  }
  deleteAvisosCobrancaHypotheses(list: Array<string>): Observable<any> {
    let url = new URL(this.baseUrl + '/configurations/avisoscobranca/deletehypotheses');
    let body = list;
    return this.http.post<any>(url.toString(), body, { headers: this.authHeaders });
  }

  getContenciososList(): Observable<any> {
    let url = new URL(this.baseUrl + '/dev/getcontenciososlist');
    return this.http.get<any>(url.toString(), { headers: this.authHeaders });
  }
}
